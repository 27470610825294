import { useCallback } from 'react';
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';

/**
 * This is just a line chart but we have another LinePlot so I don't want to get them confused right now, it's only being used for volume ramp ups right now
 * @param
 * @returns
 */
export function VolumeChart(props: { volumes: number[] }) {
  const { volumes } = props;

  const data = volumes.map((volume, index) => ({
    month: index + 1,
    volume: volume,
  }));

  // Format large numbers with M/K, ensuring we always return a string
  const formatYAxis = (value: number): string => {
    if (value >= 1000000) {
      return `${(value / 1000000).toFixed(1)}M`;
    }
    if (value >= 1000) {
      return `${(value / 1000).toFixed(1)}K`;
    }
    return value.toString();
  };

  // Calculate the maximum value to determine required width
  const maxValue = Math.max(...volumes);
  const formattedMaxValue = formatYAxis(maxValue);
  const estimatedWidth = formattedMaxValue.length * 8; // Estimate 8px per character

  const getMargins = useCallback(
    (yAxisWidth: number) => ({
      top: 15,
      right: 15,
      left: Math.max(estimatedWidth + 10, 35),
      bottom: 5,
    }),
    [estimatedWidth],
  );

  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart data={data} margin={getMargins(estimatedWidth)}>
        <CartesianGrid
          strokeDasharray="4 4"
          stroke="#e5e5e5"
          vertical={false}
        />
        <XAxis
          dataKey="month"
          axisLine={false}
          tickLine={false}
          tick={{ fill: '#666', fontSize: 11 }}
          domain={[1, volumes.length]}
          padding={{ left: 8, right: 8 }}
          dy={5}
        />
        <YAxis
          axisLine={false}
          tickLine={false}
          tick={{ fill: '#666', fontSize: 11 }}
          domain={['auto', 'auto']}
          width={estimatedWidth}
          tickFormatter={formatYAxis}
          dx={-3}
        />
        <Line
          type="stepAfter"
          dataKey="volume"
          stroke="#8b3a98"
          strokeWidth={1.5}
          dot={{
            stroke: '#8b3a98',
            strokeWidth: 1.5,
            r: 3,
            fill: 'white',
          }}
          isAnimationActive={false}
        />
      </LineChart>
    </ResponsiveContainer>
  );
}

export default VolumeChart;
