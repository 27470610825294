import { datadogRum } from '@datadog/browser-rum';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { produce, setAutoFreeze } from 'immer';
import _, { cloneDeep, isNil, last } from 'lodash';
import { Fragment, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import Badge from 'src/components/Badge';
import { FormattedNumberField } from 'src/components/Fields';
import { useToast } from 'src/components/Toast';
import { classNames } from 'src/dashboard/App';
import { Currency } from 'src/dashboard/PricingFlow/types_common/price';
import list from 'src/list';
import { getHandleKeyDownForEnterNextRowHandling } from 'src/utils';
import { formatCurrency } from 'src/utils/formatters';
import { ALPACA_CURRENCY_SYMBOLS } from '../../Alpaca/alpaca_types';
import { usePricingFlowContext } from '../../PricingFlow';
import {
  PenguinCV,
  PenguinPricingFlow,
  PenguinPricingFlowWithProductVolumes,
  PenguinProductPrices,
  PenguinProductWithVolume,
} from '../penguin_types';
import EditableIndicator from './EditableIndicator';
import { getL1AndSuggestedPriceForProduct } from './PenguinQuoteTable';

type RampRow = {
  rampValue: PenguinCV;
  key: string;
};

function Sidebar({
  isOpen,
  onClose,
  children,
  title,
}: {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  title: string;
}) {
  return createPortal(
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-0 md:pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto relative w-screen max-w-min">
                  <button
                    type="button"
                    className="absolute z-50 bg-white right-4 top-4 rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-fuchsia-500 md:hidden"
                    onClick={onClose}
                  >
                    <span className="sr-only">Close panel</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                  <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                    <div className="px-4 sm:px-6">
                      <Dialog.Title className="text-xl font-medium leading-6 text-gray-900">
                        {title}
                      </Dialog.Title>
                    </div>
                    {children}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>,
    document.body,
  );
}

const HEADINGS = [
  'Month',
  'Sticker',
  'L1 price',
  'Suggested price',
  'Quote price',
];

function Header() {
  return (
    <thead>
      <tr>
        {HEADINGS.map((heading) => (
          <th
            key={heading}
            scope="col"
            className="sticky top-0 z-10 whitespace-nowrap rounded-tl-xl border-b bg-gray-50 px-6 py-3.5 text-left text-sm font-medium text-gray-700 backdrop-blur backdrop-filter"
          >
            {heading}
          </th>
        ))}
      </tr>
    </thead>
  );
}

function BodyDataCell({ children }: { children: React.ReactNode }) {
  return (
    <td className="has-tooltip whitespace-nowrap border-b border-gray-200 px-6 py-4 text-sm font-medium">
      {children}
    </td>
  );
}

function format(amount: number) {
  return formatCurrency({
    amount,
    currency: 'USD',
    rounding: true,
    minimumFractionDigits: 3,
  });
}

function BodyRow({
  index,
  ramp,
  setRamp,
  stickerPrice,
  l1Price,
  suggestedPrice,
}: {
  stickerPrice: PenguinCV | null;
  l1Price: PenguinCV | null;
  suggestedPrice: PenguinCV | null;
  index: number;
  ramp: RampRow;
  setRamp: (ramp: RampRow) => void;
}) {
  const quotePriceInputRef = useRef<HTMLInputElement>(null);
  const { pricingFlow, editMode } = usePricingFlowContext<PenguinPricingFlow>();

  return (
    <tr>
      <BodyDataCell>Month {index}</BodyDataCell>
      <BodyDataCell>
        <button
          onClick={() => {
            if (!isNil(stickerPrice)) {
              setAutoFreeze(false);
              setRamp(
                produce(ramp, (draftRamp) => {
                  draftRamp.rampValue.value = stickerPrice.value;
                }),
              );
            }
          }}
          disabled={
            isNil(stickerPrice) || !_.isFinite(stickerPrice.value) || !editMode
          }
        >
          <Badge color="purple">
            {!isNil(stickerPrice) && _.isFinite(stickerPrice.value)
              ? format(stickerPrice.value)
              : 'N/A'}
          </Badge>
        </button>
      </BodyDataCell>
      <BodyDataCell>
        <button
          onClick={() => {
            if (!isNil(l1Price)) {
              setAutoFreeze(false);
              setRamp(
                produce(ramp, (draftRamp) => {
                  draftRamp.rampValue.value = l1Price.value;
                }),
              );
            }
          }}
          disabled={isNil(l1Price) || !_.isFinite(l1Price.value) || !editMode}
        >
          <Badge color="orange">
            {!isNil(l1Price) && _.isFinite(l1Price.value)
              ? format(l1Price.value)
              : 'N/A'}
          </Badge>
        </button>
      </BodyDataCell>
      <BodyDataCell>
        <button
          onClick={() => {
            if (!isNil(suggestedPrice) && _.isFinite(suggestedPrice.value)) {
              setAutoFreeze(false);
              setRamp(
                produce(ramp, (draftRamp) => {
                  draftRamp.rampValue.value = suggestedPrice.value;
                }),
              );
            }
          }}
          disabled={
            isNil(suggestedPrice) ||
            !_.isFinite(suggestedPrice.value) ||
            !editMode
          }
        >
          <Badge color="green">
            {!isNil(suggestedPrice) && _.isFinite(suggestedPrice.value)
              ? format(suggestedPrice.value)
              : 'N/A'}
          </Badge>
        </button>
      </BodyDataCell>
      <td
        className="borer-gray-200 border-b p-0 text-sm font-medium"
        onClick={() => {
          if (quotePriceInputRef.current !== null) {
            quotePriceInputRef.current.focus();
          }
        }}
      >
        <EditableIndicator className="h-full w-full px-6 py-4">
          <FormattedNumberField
            ref={quotePriceInputRef}
            data-ramped-quote-price-editable
            onKeyDown={getHandleKeyDownForEnterNextRowHandling(
              quotePriceInputRef,
              'data-ramped-quote-price-editable',
            )}
            type="text"
            value={ramp.rampValue.value}
            required={true}
            className="-ml-3 mr-0 max-w-[100px] cursor-pointer border-none bg-transparent py-4 pr-6 text-sm outline-none focus:border-none focus:ring-0 focus:ring-transparent"
            updateValue={(value: number) => {
              setAutoFreeze(false);
              setRamp(
                produce(ramp, (draftRamp) => {
                  draftRamp.rampValue.value = value;
                }),
              );
            }}
            numberDecimals={0}
            prefix={
              ALPACA_CURRENCY_SYMBOLS[
                (pricingFlow.additionalData?.quoteCurrency ?? 'USD') as Currency
              ]
            }
            disabled={!editMode}
          />
        </EditableIndicator>
      </td>
    </tr>
  );
}

function Body({
  ramps,
  setRampWithKey,
  stickerPrice,
  l1Price,
  suggestedPrice,
}: {
  stickerPrice: PenguinCV | null;
  l1Price: PenguinCV | null;
  suggestedPrice: PenguinCV | null;
  ramps: RampRow[];
  setRampWithKey: (key: string, ramp: RampRow) => void;
}) {
  return (
    <tbody>
      {ramps.map((ramp, index) => (
        <BodyRow
          stickerPrice={stickerPrice}
          l1Price={l1Price}
          suggestedPrice={suggestedPrice}
          key={ramp.key}
          index={index + 1}
          ramp={ramp}
          setRamp={(ramp: RampRow) => setRampWithKey(ramp.key, ramp)}
        />
      ))}
    </tbody>
  );
}

function Footer() {
  const { editMode } = usePricingFlowContext();

  return (
    <tfoot>
      <tr>
        <td colSpan={HEADINGS.length} className="bg-gray-50 px-6 py-2">
          <div className="flex justify-start"></div>
        </td>
      </tr>
    </tfoot>
  );
}

function RampedDetails(props: {
  productPrices: PenguinProductPrices;
  productIdToEdit: string | undefined;
  isOpen: boolean;
  onCancel: () => void;
  onSave: () => void;
}) {
  const { productIdToEdit, isOpen, onCancel, onSave } = props;
  const { pricingFlow } =
    usePricingFlowContext<PenguinPricingFlowWithProductVolumes>();

  const product = pricingFlow.products.find((p) => p.id === productIdToEdit) as
    | PenguinProductWithVolume
    | undefined;

  return (
    <Sidebar
      isOpen={isOpen}
      onClose={onCancel}
      title={`Pricing ramp for ${product?.name ?? ''}`}
    >
      {productIdToEdit !== undefined ? (
        <Editor
          {...props}
          productId={productIdToEdit}
          onSave={onSave}
          onCancel={onCancel}
        />
      ) : null}
    </Sidebar>
  );
}

function Editor({
  productPrices,
  productId,
  onCancel,
  onSave,
}: {
  productPrices: PenguinProductPrices;
  productId: string;
  onCancel: () => void;
  onSave: () => void;
}) {
  const { showToast } = useToast();
  const { pricingFlow, updateFlow, editMode } =
    usePricingFlowContext<PenguinPricingFlowWithProductVolumes>();
  const { manualQuote } = pricingFlow;
  const quotePrice = manualQuote.products?.[productId];
  const product = pricingFlow.products.find((p) => p.id === productId) as
    | PenguinProductWithVolume
    | undefined;

  if (product === undefined) {
    throw new Error(
      `Attempting to edit ramps for missing product ${productId}`,
    );
  }
  const pricingInfo =
    productPrices[product.id]?.currentPricingCurve.pricingInformation;

  const stickerPrice = pricingInfo?.selfServe;

  const { L1Price, suggestedPrice } = getL1AndSuggestedPriceForProduct({
    product: product,
    pricingFlow: pricingFlow,
  });

  if (isNil(quotePrice) || quotePrice.type !== 'ramped') {
    throw new Error(
      `Attempting to edit ramps of non ramped product quote ${quotePrice} for ${product.name}`,
    );
  }
  const initialRamps: RampRow[] = quotePrice.rampValues
    ? quotePrice.rampValues.map((rampValue: PenguinCV) => {
        return {
          rampValue,
          key: _.uniqueId(),
        };
      })
    : [];

  const [ramps, setRamps] = useState<RampRow[]>(initialRamps);

  const setRampWithKey = (key: string, newRamp: RampRow) => {
    setRamps((ramps) => {
      const index = list.findIndexOrNull(ramps, (ramp) => ramp.key === key);
      if (isNil(index)) {
        datadogRum.addError(`Found no ramp with key ${key}. Nothing to update`);
        return ramps;
      }

      setAutoFreeze(false);
      return produce(ramps, (draftRamps) => {
        draftRamps[index] = newRamp;
      });
    });
  };

  const handleCancel = () => {
    onCancel();
  };

  const validateRamps = (): boolean => {
    // SFDC doesn't validate that all ramps are increasing (yet?)
    // for (let i = 0; i < ramps.length; i++) {
    //   if (i !== 0 && ramps[i - 1].rampValue.value >= ramps[i].rampValue.value) {
    //     showToast({
    //       title: 'The ramp prices must be increasing',
    //       subtitle: '',
    //       type: 'error',
    //     });
    //     return false;
    //   }
    // }

    // Validate that last ramp is the largest ramp
    // SFDC doesn't validate this either, but I think it's probably user error if you don't do this
    const lastRamp = _.last(ramps);
    if (isNil(lastRamp)) {
      throw new Error(`Unexpectedly got empty ramps`);
    }
    for (let i = 0; i < ramps.length; i++) {
      if (lastRamp.rampValue.value < ramps[i].rampValue.value) {
        showToast({
          title: 'The last price in the ramp must be the largest',
          subtitle: '',
          type: 'error',
        });
        return false;
      }
    }
    return true;
  };

  const handleSave = () => {
    if (!validateRamps()) {
      return;
    }

    setAutoFreeze(false);
    const newFlow: PenguinPricingFlowWithProductVolumes = produce(
      pricingFlow,
      (draftFlow) => {
        if (!isNil(draftFlow.manualQuote.products)) {
          draftFlow.manualQuote.products[productId] = {
            type: 'ramped',
            rampValues: ramps.map((ramp) => ramp.rampValue),
          };
        }
      },
    );
    updateFlow(newFlow, false);
    onSave();
  };

  return (
    <div className="px-6 py-4">
      <div className="mx-2 my-8 border-none border-gray-200 flex items-center">
        <label
          htmlFor="last-ramp-value"
          className="block text-sm font-medium  text-slate-700"
        >
          Final month's fee
        </label>
        <FormattedNumberField
          id="last-ramp-value"
          type="text"
          value={_.last(ramps)?.rampValue.value}
          required={true}
          className="mx-4 text-sm w-44 rounded-lg border border-gray-300 bg-transparent p-2 text-gray-900 shadow-sm outline-none focus-within:border-none focus-within:outline focus-within:outline-2 focus-within:outline-fuchsia-900 focus:border-none focus:ring-0 focus:ring-transparent"
          updateValue={(value: number) => {
            setAutoFreeze(false);
            setRamps(
              produce(ramps, (draftRamps) => {
                draftRamps[draftRamps.length - 1].rampValue.value = value;
              }),
            );
          }}
          numberDecimals={0}
          prefix={
            ALPACA_CURRENCY_SYMBOLS[
              (pricingFlow.additionalData?.quoteCurrency ?? 'USD') as Currency
            ]
          }
          disabled={!editMode}
        />
        <button
          type="button"
          className="rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          onClick={() => {
            setRamps(
              produce(ramps, (draftRamps) => {
                const lastRamp = last(ramps);
                if (isNil(lastRamp)) {
                  throw new Error(`ramps were unexpectedly empty`);
                }
                draftRamps.forEach(
                  (ramp) => (ramp.rampValue = cloneDeep(lastRamp.rampValue)),
                );
              }),
            );
          }}
        >
          Reset to flat ramp
        </button>
      </div>
      <div className="rounded-xl border border-gray-200 bg-white">
        <table className="h-full">
          <Header />
          <Body
            stickerPrice={stickerPrice ?? null}
            l1Price={L1Price}
            suggestedPrice={suggestedPrice}
            ramps={ramps}
            setRampWithKey={setRampWithKey}
          />
          <Footer />
        </table>
      </div>
      <Savebar onCancel={handleCancel} onSave={handleSave} />
    </div>
  );
}

function Savebar({
  onCancel,
  onSave,
}: {
  onCancel: React.MouseEventHandler<HTMLButtonElement>;
  onSave: React.MouseEventHandler<HTMLButtonElement>;
}) {
  const { editMode } = usePricingFlowContext();
  return (
    <div className="mt-8 flex flex-row items-center justify-end gap-2">
      <button
        type="button"
        className="col-span-full justify-center rounded-lg border border-gray-200 bg-white px-5 py-2 font-semibold text-black shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-fuchsia-900"
        onClick={onCancel}
      >
        Cancel
      </button>

      <button
        type="submit"
        className={classNames(
          'col-span-full justify-center rounded-lg border border-fuchsia-900 bg-fuchsia-900 px-5 py-2 font-semibold text-white shadow-sm hover:bg-fuchsia-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-fuchsia-900',
          !editMode ? 'cursor-not-allowed opacity-50' : '',
        )}
        onClick={onSave}
        disabled={!editMode}
      >
        Save
      </button>
    </div>
  );
}

export default RampedDetails;
