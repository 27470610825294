import Button from 'src/components/Button';

interface SaveOrEditButtonsProps {
  isEditing: boolean;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
  onSave: () => Promise<{ success: boolean }>;
  onCancel: () => void;
  disabled?: boolean;
}
export default function SaveOrEditButtons(props: SaveOrEditButtonsProps) {
  const { isEditing, setIsEditing, onCancel, onSave, disabled } = props;
  if (isEditing) {
    // show save and cancel buttons
    return (
      // We need to add an extra -1px because the white button has a border
      <div className="flex gap-x-2 my-[-1px]">
        <Button
          color="noBg"
          onClick={() => {
            onCancel();
            setIsEditing(false);
          }}
          label="Cancel"
          disabled={disabled}
        />
        <Button
          color="primary"
          onClick={async () => {
            const { success } = await onSave();
            if (success) setIsEditing(false);
          }}
          label="Save"
          disabled={disabled}
        />
      </div>
    );
  } else {
    // show Edit button
    return (
      <Button
        color="noBg"
        onClick={() => {
          setIsEditing(true);
        }}
        label="Edit"
        disabled={disabled}
      />
    );
  }
}
