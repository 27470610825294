import { datadogRum } from '@datadog/browser-rum';
import { isNil } from 'lodash';
import { useEffect, useState } from 'react';
import { FormattedNumberField } from 'src/components/Fields';
import Tooltip from 'src/components/Tooltip';
import { classNames } from 'src/dashboard/App';
import { usePricingFlowContext } from 'src/dashboard/PricingFlow/PricingFlow';
import {
  CurrencyValuePercent,
  CurrencyValueType,
} from 'src/dashboard/PricingFlow/types_common/price';
import { AlpacaPricingFlow, ALPACA_CURRENCY_SYMBOLS } from '../../alpaca_types';
import { getCurrentIssuingEntity } from '../../Issuing';
import { RegionalData } from '../../IssuingData';
import { entityHasIntraregionalConcept } from '../AlpacaIssuingTable';
import AlpacaRangeSlider from './AlpacaMultiRangeSlider/AlpacaRangeSlider';

function Alpaca2WayRegionalBreakdown({
  updateRegionalBreakdown,
}: {
  updateRegionalBreakdown: (
    newRegionalBreakdown: RegionalData<CurrencyValuePercent>,
  ) => void;
}) {
  const { pricingFlow, editMode } = usePricingFlowContext<AlpacaPricingFlow>();
  const currentEntity = getCurrentIssuingEntity(pricingFlow);
  const monthlySpendAtScale = currentEntity.monthlySpendAtScale;
  // goes from 0 to 100
  const [separator, setSeparator] = useState<number>(
    currentEntity.regionalBreakdown.domestic.value,
  );

  useEffect(() => {
    setSeparator(currentEntity.regionalBreakdown.domestic.value);
  }, [currentEntity.name]);

  const updateRegionalBreakdownForValue = (value: number) => {
    if (isNil(value)) {
      datadogRum.addError(
        new Error(`got nil value when trying to update regional breakdown`),
      );
      return;
    }
    setSeparator(value);
    updateRegionalBreakdown({
      domestic: { type: CurrencyValueType.PERCENT, value },
      international: { type: CurrencyValueType.PERCENT, value: 100 - value },
      intraRegional: { type: CurrencyValueType.PERCENT, value: 0 },
    });
  };

  return (
    <div>
      <label className="text-sm font-medium text-gray-900">
        Mix of domestic vs international spend
      </label>
      <input
        type="range"
        min="0"
        max="100"
        value={separator}
        className="h-6 w-full cursor-pointer accent-fuchsia-800"
        onChange={(e) => setSeparator(Number(e.target.value))}
        onMouseUp={() => {
          updateRegionalBreakdownForValue(separator);
        }}
        disabled={!editMode}
      />
      <div className="grid grid-cols-3 gap-4">
        {/* Domestic Field */}
        <div className="flex flex-col gap-2">
          {/* domestic Currency Field */}
          <FormattedNumberField
            type="text"
            label={
              <label className={'block text-sm font-medium text-gray-900'}>
                Domestic
              </label>
            }
            value={(separator * monthlySpendAtScale.value) / 100}
            numberDecimals={2}
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-fuchsia-900 focus:border-fuchsia-900 block w-full p-2.5"
            updateValue={(value: number) => {
              updateRegionalBreakdownForValue(
                (100 * value) / currentEntity.monthlySpendAtScale.value,
              );
            }}
            prefix={ALPACA_CURRENCY_SYMBOLS[monthlySpendAtScale.currency]}
            isAllowed={({
              floatValue,
            }: {
              formattedValue: string;
              value: string;
              floatValue: number;
            }) => {
              if (isNil(floatValue)) return true;
              return (
                Number(floatValue) >= 0 &&
                Number(floatValue) <= monthlySpendAtScale.value
              );
            }}
            disabled={!editMode}
          />
          {/* domestic Percent Field */}
          <FormattedNumberField
            type="text"
            value={separator}
            numberDecimals={2}
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-fuchsia-900 focus:border-fuchsia-900 block w-full p-2.5 "
            updateValue={(value: number) => {
              updateRegionalBreakdownForValue(value);
            }}
            suffix="%"
            isAllowed={({
              floatValue,
            }: {
              formattedValue: string;
              value: string;
              floatValue: number;
            }) => {
              if (isNil(floatValue)) return true;
              return Number(floatValue) >= 0 && Number(floatValue) <= 100;
            }}
            disabled={!editMode}
          />
        </div>
        {/* Intraregional Field */}
        <Tooltip as="div" text="Not applicable for US" location="BOTTOM">
          <div className="flex flex-col gap-2">
            {/* intrareginoal Currency Field */}
            <FormattedNumberField
              type="text"
              label={
                <label className={'block text-sm font-medium text-gray-900'}>
                  Intra-regional
                </label>
              }
              value={0}
              numberDecimals={2}
              className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-fuchsia-900 focus:border-fuchsia-900 block w-full p-2.5 bg-gray-100"
              updateValue={(value: number) => {}}
              prefix={ALPACA_CURRENCY_SYMBOLS[monthlySpendAtScale.currency]}
              isAllowed={() => {
                return false;
              }}
              disabled={true}
            />
            {/* intraregional Percent Field */}
            <FormattedNumberField
              type="text"
              value={0}
              numberDecimals={2}
              className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-fuchsia-900 focus:border-fuchsia-900 block w-full p-2.5 bg-gray-100"
              updateValue={(value: number) => {}}
              suffix="%"
              isAllowed={() => {
                return false;
              }}
              disabled={true}
            />
          </div>
        </Tooltip>
        {/* International Field */}
        <div className="flex flex-col gap-2">
          {/* international Currency Field */}
          <FormattedNumberField
            type="text"
            label={
              <label className={'block text-sm font-medium text-gray-900'}>
                International
              </label>
            }
            value={(monthlySpendAtScale.value * (100 - separator)) / 100}
            numberDecimals={2}
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-fuchsia-900 focus:border-fuchsia-900 block w-full p-2.5 "
            updateValue={(value: number) => {
              updateRegionalBreakdownForValue(
                (100 * (monthlySpendAtScale.value - value)) /
                  monthlySpendAtScale.value,
              );
            }}
            prefix={ALPACA_CURRENCY_SYMBOLS[monthlySpendAtScale.currency]}
            isAllowed={({
              floatValue,
            }: {
              formattedValue: string;
              value: string;
              floatValue: number;
            }) => {
              if (isNil(floatValue)) return true;
              return (
                Number(floatValue) >= 0 &&
                Number(floatValue) <= monthlySpendAtScale.value
              );
            }}
            disabled={!editMode}
          />
          {/* international Percent Field */}
          <FormattedNumberField
            type="text"
            value={100 - separator}
            numberDecimals={2}
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-fuchsia-900 focus:border-fuchsia-900 block w-full p-2.5 "
            updateValue={(value: number) => {
              updateRegionalBreakdownForValue(100 - value);
            }}
            suffix="%"
            isAllowed={({
              floatValue,
            }: {
              formattedValue: string;
              value: string;
              floatValue: number;
            }) => {
              if (isNil(floatValue)) return true;
              return Number(floatValue) >= 0 && Number(floatValue) <= 100;
            }}
            disabled={!editMode}
          />
        </div>
      </div>
    </div>
  );
}
function Alpaca3WayRegionalBreakdown({
  updateRegionalBreakdown,
}: {
  updateRegionalBreakdown: (
    newRegionalBreakdown: RegionalData<CurrencyValuePercent>,
  ) => void;
}) {
  const { pricingFlow, editMode } = usePricingFlowContext<AlpacaPricingFlow>();
  const currentEntity = getCurrentIssuingEntity(pricingFlow);
  const monthlySpendAtScale = currentEntity.monthlySpendAtScale;
  const [firstSeparator, setFirstSeparator] = useState<number>(
    currentEntity.regionalBreakdown.domestic.value,
  );
  const [secondSeparator, setSecondSeparator] = useState<number>(
    currentEntity.regionalBreakdown.domestic.value +
      currentEntity.regionalBreakdown.intraRegional.value,
  );
  useEffect(() => {
    setFirstSeparator(currentEntity.regionalBreakdown.domestic.value);
    setSecondSeparator(
      currentEntity.regionalBreakdown.domestic.value +
        currentEntity.regionalBreakdown.intraRegional.value,
    );
  }, [currentEntity.name]);

  const updateAndRecalculateRegionalBreakdown = (
    changed: 'domestic' | 'intraRegional' | 'international',
    newValue?: number,
    newPercent?: number,
  ) => {
    if (!isNil(newValue)) {
      newPercent = (newValue / monthlySpendAtScale.value) * 100;
    }

    // if change is no-op, do nothing
    if (changed === 'domestic' && newPercent === firstSeparator) {
      return;
    } else if (
      changed === 'intraRegional' &&
      newPercent === secondSeparator - firstSeparator
    ) {
      return;
    } else if (
      changed === 'international' &&
      newPercent === 100 - secondSeparator
    ) {
      return;
    }

    let newFirstSeparator: number = firstSeparator;
    let newSecondSeparator: number = secondSeparator;

    if (isNil(newPercent)) {
      datadogRum.addError('newPercent was blank, which should be impossible.');
      return; // shouldn't be reachable
    }

    if (changed === 'domestic') {
      newFirstSeparator = newPercent;
      setFirstSeparator(newFirstSeparator);
      if (newFirstSeparator > secondSeparator) {
        newSecondSeparator = newFirstSeparator;
        setSecondSeparator(newSecondSeparator);
      }
    } else if (changed === 'intraRegional') {
      newSecondSeparator = firstSeparator + newPercent;
      if (newSecondSeparator >= 100) {
        newFirstSeparator -= newSecondSeparator - 100;
        newSecondSeparator = 100;
        setFirstSeparator(newFirstSeparator);
        setSecondSeparator(newSecondSeparator);
      } else {
        setSecondSeparator(newSecondSeparator);
      }
    } else if (changed === 'international') {
      newSecondSeparator = 100 - newPercent;
      if (newFirstSeparator > newSecondSeparator) {
        newFirstSeparator = newSecondSeparator;
        setFirstSeparator(newFirstSeparator);
      }

      setSecondSeparator(newSecondSeparator);
    }
    if (isNil(newFirstSeparator) || isNil(newSecondSeparator)) {
      datadogRum.addError(
        new Error(
          `nil value while updating regional breakdown ${newFirstSeparator} ${newSecondSeparator}`,
        ),
      );
      return;
    }
    const newRegionalBreakdown = separatorsToRegionalBreakdown(
      newFirstSeparator,
      newSecondSeparator,
    );
    updateRegionalBreakdown(newRegionalBreakdown);
  };

  return (
    <div>
      <label className="text-sm font-medium text-gray-900">
        Mix of domestic vs intra-regional vs international spend
      </label>
      <AlpacaRangeSlider
        setFirstSeparator={setFirstSeparator}
        setSecondSeparator={setSecondSeparator}
        firstSeparator={firstSeparator}
        secondSeparator={secondSeparator}
        onMouseUp={() => {
          const newRegionalBreakdown = separatorsToRegionalBreakdown(
            firstSeparator,
            secondSeparator,
          );
          updateRegionalBreakdown(newRegionalBreakdown);
        }}
      />
      <div className="grid grid-cols-3 gap-4">
        {/* Domestic Field */}
        <div className="flex flex-col gap-2">
          {/* domestic Currency Field */}
          <Tooltip
            as="div"
            className="flex flex-col gap-2"
            text="Set monthly spend at scale to configure"
            location="TOP"
            disableTooltip={monthlySpendAtScale.value !== 0}
          >
            <FormattedNumberField
              type="text"
              label={
                <label className={'block text-sm font-medium text-gray-900'}>
                  Domestic
                </label>
              }
              value={(firstSeparator * monthlySpendAtScale.value) / 100}
              numberDecimals={2}
              className={classNames(
                monthlySpendAtScale.value === 0 ? 'bg-gray-100' : '',
                'border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-fuchsia-900 focus:border-fuchsia-900 block w-full p-2.5 ',
              )}
              updateValue={(value: number) => {
                updateAndRecalculateRegionalBreakdown('domestic', value);
              }}
              prefix={ALPACA_CURRENCY_SYMBOLS[monthlySpendAtScale.currency]}
              isAllowed={({
                floatValue,
              }: {
                formattedValue: string;
                value: string;
                floatValue: number;
              }) => {
                if (isNil(floatValue)) return true;
                return (
                  Number(floatValue) >= 0 &&
                  Number(floatValue) <= monthlySpendAtScale.value
                );
              }}
              disabled={monthlySpendAtScale.value === 0 || !editMode}
            />
          </Tooltip>
          {/* domestic Percent Field */}
          <FormattedNumberField
            type="text"
            value={firstSeparator}
            numberDecimals={2}
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-fuchsia-900 focus:border-fuchsia-900 block w-full p-2.5 "
            updateValue={(value: number) => {
              updateAndRecalculateRegionalBreakdown(
                'domestic',
                undefined,
                value,
              );
            }}
            suffix="%"
            isAllowed={({
              floatValue,
            }: {
              formattedValue: string;
              value: string;
              floatValue: number;
            }) => {
              if (isNil(floatValue)) return true;
              return Number(floatValue) >= 0 && Number(floatValue) <= 100;
            }}
            disabled={!editMode}
          />
        </div>
        {/* Intraregional Field */}
        <div className="flex flex-col gap-2">
          {/* intraregional Currency Field */}
          <Tooltip
            as="div"
            className="flex flex-col gap-2"
            text="Set monthly spend at scale to configure"
            location="TOP"
            disableTooltip={monthlySpendAtScale.value !== 0}
          >
            <FormattedNumberField
              type="text"
              label={
                <label className={'block text-sm font-medium text-gray-900'}>
                  Intra-regional
                </label>
              }
              value={
                ((secondSeparator - firstSeparator) *
                  monthlySpendAtScale.value) /
                100
              }
              numberDecimals={2}
              className={classNames(
                monthlySpendAtScale.value === 0 ? 'bg-gray-100' : '',
                'border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-fuchsia-900 focus:border-fuchsia-900 block w-full p-2.5 ',
              )}
              updateValue={(value: number) => {
                updateAndRecalculateRegionalBreakdown('intraRegional', value);
              }}
              prefix={ALPACA_CURRENCY_SYMBOLS[monthlySpendAtScale.currency]}
              isAllowed={({
                floatValue,
              }: {
                formattedValue: string;
                value: string;
                floatValue: number;
              }) => {
                if (isNil(floatValue)) return true;
                return (
                  Number(floatValue) >= 0 &&
                  Number(floatValue) <= monthlySpendAtScale.value
                );
              }}
              disabled={monthlySpendAtScale.value === 0 || !editMode}
            />
          </Tooltip>
          {/* intraregional Percent Field */}
          <FormattedNumberField
            type="text"
            value={secondSeparator - firstSeparator}
            numberDecimals={2}
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-fuchsia-900 focus:border-fuchsia-900 block w-full p-2.5 "
            updateValue={(value: number) => {
              updateAndRecalculateRegionalBreakdown(
                'intraRegional',
                undefined,
                value,
              );
            }}
            suffix="%"
            isAllowed={({
              floatValue,
            }: {
              formattedValue: string;
              value: string;
              floatValue: number;
            }) => {
              if (isNil(floatValue)) return true;
              return Number(floatValue) >= 0 && Number(floatValue) <= 100;
            }}
            disabled={!editMode}
          />
        </div>
        {/* International Field */}
        <div className="flex flex-col gap-2">
          {/* internatinoal Currency Field */}
          <Tooltip
            as="div"
            className="flex flex-col gap-2"
            text="Set monthly spend at scale to configure"
            location="TOP"
            disableTooltip={monthlySpendAtScale.value !== 0}
          >
            <FormattedNumberField
              type="text"
              label={
                <label className={'block text-sm font-medium text-gray-900'}>
                  International
                </label>
              }
              value={
                ((100 - secondSeparator) * monthlySpendAtScale.value) / 100
              }
              numberDecimals={2}
              className={classNames(
                monthlySpendAtScale.value === 0 ? 'bg-gray-100' : '',
                'border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-fuchsia-900 focus:border-fuchsia-900 block w-full p-2.5 ',
              )}
              updateValue={(value: number) => {
                updateAndRecalculateRegionalBreakdown('international', value);
              }}
              prefix={ALPACA_CURRENCY_SYMBOLS[monthlySpendAtScale.currency]}
              isAllowed={({
                floatValue,
              }: {
                formattedValue: string;
                value: string;
                floatValue: number;
              }) => {
                if (isNil(floatValue)) return true;
                return (
                  Number(floatValue) >= 0 &&
                  Number(floatValue) <= monthlySpendAtScale.value
                );
              }}
              disabled={monthlySpendAtScale.value === 0 || !editMode}
            />
          </Tooltip>
          {/* international Percent Field */}
          <FormattedNumberField
            type="text"
            value={100 - secondSeparator}
            numberDecimals={2}
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-fuchsia-900 focus:border-fuchsia-900 block w-full p-2.5 "
            updateValue={(value: number) => {
              updateAndRecalculateRegionalBreakdown(
                'international',
                undefined,
                value,
              );
            }}
            suffix="%"
            isAllowed={({
              floatValue,
            }: {
              formattedValue: string;
              value: string;
              floatValue: number;
            }) => {
              if (isNil(floatValue)) return true;
              return Number(floatValue) >= 0 && Number(floatValue) <= 100;
            }}
            disabled={!editMode}
          />
        </div>
      </div>
    </div>
  );
}

const AlpacaRegionalBreakdown = ({
  updateRegionalBreakdown,
}: {
  updateRegionalBreakdown: (
    newRegionalBreakdown: RegionalData<CurrencyValuePercent>,
  ) => void;
}) => {
  const { pricingFlow } = usePricingFlowContext<AlpacaPricingFlow>();
  const currentEntity = getCurrentIssuingEntity(pricingFlow);
  if (entityHasIntraregionalConcept(currentEntity)) {
    return (
      <Alpaca3WayRegionalBreakdown
        updateRegionalBreakdown={updateRegionalBreakdown}
      />
    );
  } else {
    return (
      <Alpaca2WayRegionalBreakdown
        updateRegionalBreakdown={updateRegionalBreakdown}
      />
    );
  }
};

function separatorsToRegionalBreakdown(
  firstSeparator: number,
  secondSeparator: number,
): RegionalData<CurrencyValuePercent> {
  return {
    domestic: {
      type: CurrencyValueType.PERCENT,
      value: firstSeparator,
    },
    intraRegional: {
      type: CurrencyValueType.PERCENT,
      value: secondSeparator - firstSeparator,
    },
    international: {
      type: CurrencyValueType.PERCENT,
      value: 100 - secondSeparator,
    },
  };
}

export default AlpacaRegionalBreakdown;
