import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from 'src/api';
import backgroundLines from 'src/images/background-lines.svg';
import fadedCircleBg from 'src/images/faded-circle-bg.svg';
import logoDealopsTarget from 'src/images/logos/dealops-target.svg';
import { Organization, User } from '../../types';

import { datadogRum } from '@datadog/browser-rum';
import dayjs from 'dayjs';
import { useAnalyticsContext } from 'src/components/AnalyticsContext';
import Badge from 'src/components/Badge';
import { getOpportunityIdFromIdOrUrl } from '../../utils/formatters';
import { Opportunity } from './types';

interface OpportunitySearchPageProps {
  user: User;
  organization: Organization;
}

interface SalesforceOpportunity {
  Id: string;
  Name: string;
  StageName: string;
  CloseDate: string;
  Amount: number;
  Account: {
    Name: string;
  } | null;
  Owner: {
    Name: string;
  } | null;
  LastModifiedDate: string;
}

export default function OpportunitySearchPage(
  props: OpportunitySearchPageProps,
) {
  const navigate = useNavigate();
  const createAnalyticsEvent = useAnalyticsContext();
  useEffect(() => {
    createAnalyticsEvent({
      name: 'opportunity_search_page__loaded',
      eventData: {},
    });
  }, []);

  return (
    <div className="min-h-screen w-full">
      <div
        className="relative flex flex-col items-center justify-start px-4 bg-repeat-x"
        style={{ backgroundImage: `url(${backgroundLines})` }}
      >
        {/* Dealops target logo */}
        <div className="mt-12 sm:mt-20 h-16 sm:h-24 w-16 sm:w-24 relative">
          <img
            className="absolute h-16 sm:h-24 w-16 sm:w-24"
            src={fadedCircleBg}
            alt="faded circle"
          />
          <div className="absolute ml-3 sm:ml-5 mt-3 sm:mt-5 flex h-10 sm:h-14 w-10 sm:w-14 items-center justify-center rounded-full border border-gray-200 bg-white shadow">
            <img
              className="h-5 sm:h-7 w-5 sm:w-7"
              src={logoDealopsTarget}
              alt="Dealops"
            />
          </div>
        </div>

        <h1 className="w-full text-center pt-4 sm:pt-6 text-xl sm:text-2xl font-semibold">
          Hi {props.user.name?.split(' ')[0]}, let's work on pricing!
        </h1>

        <p className="w-full text-center pt-2 text-sm sm:text-base text-gray-700">
          Enter a Salesforce opportunity ID to get started.
        </p>

        <form
          onSubmit={(e) => {
            e.preventDefault();
            const id = getOpportunityIdFromIdOrUrl(
              e.currentTarget.opportunity.value,
            );
            navigate(id, { relative: 'path' });
          }}
          className="w-full max-w-sm mt-6 sm:mt-8 px-4"
        >
          <div className="mb-3 sm:mb-4">
            <input
              type="text"
              name="opportunity"
              required
              placeholder="Enter Opportunity ID or URL"
              className="block w-full rounded-md border border-gray-300 px-3 sm:px-4 py-2 text-sm shadow-sm focus:border-fuchsia-800 focus:outline-none focus:ring-fuchsia-800"
            />
          </div>
          <button
            type="submit"
            className="w-full rounded-md border border-transparent bg-fuchsia-900 px-3 sm:px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-fuchsia-950"
          >
            Find Opportunity
          </button>
        </form>

        <div className="w-full max-w-md px-4 mb-8">
          <OpportunitiesList user={props.user} />
        </div>
      </div>
    </div>
  );
}

function OpportunitiesList(props: { user: User }) {
  const [sfdcOpportunities, setSfdcOpportunities] = useState<
    SalesforceOpportunity[] | null
  >(null);
  const [dealopsOpportunities, setDealopsOpportunities] = useState<
    Opportunity[] | null
  >(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (props.user.salesforceUserId) {
      const fetchOpportunities = async () => {
        try {
          const response = await api.get('opportunities/recent-salesforce');
          setSfdcOpportunities(response.data);
        } catch (err) {
          datadogRum.addError(err);
          console.error(err);
          setSfdcOpportunities([]);
        }
      };

      fetchOpportunities();
    }
  }, []);

  useEffect(() => {
    const fetchOpportunities = async () => {
      try {
        const response = await api.get('opportunities');
        setDealopsOpportunities(response.data);
      } catch (err) {
        datadogRum.addError(err);
        console.error(err);
        setDealopsOpportunities([]);
      }
    };

    fetchOpportunities();
  }, []);

  const opportunities: (SalesforceOpportunity | Opportunity)[] = (() => {
    // Create a Set of Salesforce IDs that exist in Dealops for O(1) lookup
    const dealopsIds = new Set(
      dealopsOpportunities?.map((opp) => opp.sfdcOpportunityId) ?? [],
    );

    // Filter Salesforce opportunities using Set lookup (O(1)) instead of .some() (O(n))
    const uniqueSfdcOpps = (sfdcOpportunities ?? []).filter(
      (sfdcOpp) => !dealopsIds.has(sfdcOpp.Id),
    );

    // Combine the arrays
    return [...(dealopsOpportunities ?? []), ...uniqueSfdcOpps];
  })();

  // Create a Map for date lookup to avoid repeated property checks and date parsing
  const dateCache = new Map<string | number, number>();
  const getModDate = (opp: SalesforceOpportunity | Opportunity): number => {
    // Use object ID or another unique identifier as cache key
    const key = 'Id' in opp ? opp.Id : opp.sfdcOpportunityId;

    if (!dateCache.has(key)) {
      const date =
        'LastModifiedDate' in opp
          ? new Date(opp.LastModifiedDate).getTime()
          : new Date(opp.updatedAt).getTime();
      dateCache.set(key, date);
    }

    return dateCache.get(key)!;
  };

  // Sort using cached date values
  opportunities.sort((a, b) => getModDate(b) - getModDate(a));

  return (
    <div className="border border-gray-200 rounded-xl mt-6 sm:mt-8 h-[460px] bg-white flex flex-col">
      <div className="uppercase text-xs rounded-t-xl text-slate-600 p-2 border-b border-gray-200 bg-white">
        Recent opportunities
      </div>

      <div className="flex-1 overflow-auto px-2">
        <div className="w-full flex flex-col">
          {opportunities.length === 0 && (
            <div className="text-gray-500 text-sm p-2">
              No opportunities found
            </div>
          )}
          {opportunities.map((opportunity) => (
            <div
              key={'Id' in opportunity ? opportunity.Id : opportunity.id}
              className="w-full mt-2 border-b border-gray-200 last:border-b-0 pb-2"
            >
              {'Id' in opportunity ? (
                <SalesforceOpportunityDisplay opportunity={opportunity} />
              ) : (
                <DealopsOpportunityDisplay opportunity={opportunity} />
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

function SalesforceOpportunityDisplay(params: {
  opportunity: SalesforceOpportunity;
}) {
  const { opportunity } = params;
  const navigate = useNavigate();
  return (
    <button
      className="flex flex-row justify-between p-2 rounded-md hover:bg-gray-100 w-full group"
      onClick={() => {
        navigate(`/app/opportunity/${opportunity.Id}`);
      }}
    >
      <div className="flex flex-col text-left min-w-0 flex-1 mr-2 sm:mr-4">
        <span className="text-xs sm:text-sm font-medium text-slate-900 truncate group-hover:text-clip group-hover:whitespace-normal">
          {opportunity.Name}
        </span>
        <span className="text-xs text-gray-500">New from Salesforce</span>
      </div>
      <div className="flex-shrink-0">
        <Badge color="gray">
          {dayjs(opportunity.LastModifiedDate).fromNow()}
        </Badge>
      </div>
    </button>
  );
}

function DealopsOpportunityDisplay(params: { opportunity: Opportunity }) {
  const { opportunity } = params;
  const navigate = useNavigate();
  return (
    <button
      className="flex flex-row justify-between p-2 rounded-md hover:bg-gray-100 w-full group"
      onClick={() => {
        navigate(`/app/opportunity/${opportunity.sfdcOpportunityId}`);
      }}
    >
      <div className="flex flex-col text-left min-w-0 flex-1 mr-2 sm:mr-4">
        <span className="text-xs sm:text-sm font-medium text-slate-900 truncate group-hover:text-clip group-hover:whitespace-normal">
          {opportunity.sfdcOpportunityName}
        </span>
        <span className="text-xs text-gray-500">
          {opportunity.pricingFlows?.length} quotes
        </span>
      </div>
      <div className="flex-shrink-0">
        <Badge color="gray">{dayjs(opportunity.updatedAt).fromNow()}</Badge>
      </div>
    </button>
  );
}
