import { datadogRum } from '@datadog/browser-rum';
import { CalculatorIcon, DocumentTextIcon } from '@heroicons/react/24/outline';
import dayjs from 'dayjs';
import _, { isNil } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import api from 'src/api';
import Button from 'src/components/Button';
import CollapsibleSection from 'src/components/CollapsibleSection';
import EditableTitle from 'src/components/EditableTitle';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import { useModal } from 'src/components/Modal';
import { classNames } from 'src/dashboard/App';
import { useOpportunityContext } from 'src/dashboard/Opportunity/OpportunityContext';
import {
  Context,
  OpportunityInfo,
} from 'src/dashboard/Opportunity/OpportunityDetailPage';
import { Organization, User } from 'src/types';
import { getNameForPricingFlow } from 'src/utils';
import {
  Activity,
  ActivityIconType,
  ActivitySection,
  CommentThread,
  isSystemActivity,
  SystemActivity,
} from '../Activity/ActivitySection';
import { ApprovalAction, ApprovalRequest } from '../Approvals/types';
import BottomBar from '../Penguin/Components/BottomBar';
import { usePricingFlowContext } from '../PricingFlow';
import PricingFlowList from '../PricingFlowList';
import { OpportunityCommon, PricingFlowCommon } from '../types';
import HamsterAnnualRevenueTable from './HamsterAnnualRevenueTable';
import HamsterExitModal from './HamsterExitModal';
import HamsterQuoteTable from './HamsterQuoteTable';
import HamsterTermsSection from './HamsterTermsSection';
import { ApprovalsDisplay, HamsterPricingFlow } from './hamster_types';

// #ActionToPastTense
function actionToPastTense(action: ApprovalAction['action']): {
  pastTense: string;
  icon: ActivityIconType;
} {
  switch (action) {
    case 'APPROVE':
      return { pastTense: 'approved', icon: 'check-circle' };
    case 'REJECT':
      return { pastTense: 'rejected', icon: 'x-circle' };
    default:
      datadogRum.addError(`Unexpected action: ${action}`);
      return {
        pastTense: `${(action as string).toLowerCase()}d`,
        icon: 'calendar',
      };
  }
}

interface HamsterPricingFlowPageProps {
  user: User;
  organization: Organization;
  viewMode?: 'group';
}

export default function HamsterPricingFlowPage(
  props: HamsterPricingFlowPageProps,
) {
  const { user } = props;
  const { showModal, hideModal } = useModal();
  const {
    pricingFlow,
    modelType,
    editMode,
    updateFlow,
    currentApprovalRequest,
  } = usePricingFlowContext<HamsterPricingFlow>();
  const { opportunity, pageConfig, updateOpportunity } =
    useOpportunityContext();

  const [approvalsNeeded, setApprovalsNeeded] = useState<ApprovalsDisplay>({
    fieldsNeeded: {},
    productsNeeded: {},
    stepSnapshots: [],
  });
  const [showActivity, setShowActivity] = useState(
    !isNil(currentApprovalRequest),
  );
  const [showOpportunityInfo, setShowOpportunityInfo] = useState(
    props.viewMode !== 'group' && !isNil(currentApprovalRequest),
  );

  const showSidePanel = showActivity || showOpportunityInfo;

  const activityRef = useRef<HTMLDivElement>(null);
  const opportunityRef = useRef<HTMLDivElement>(null);
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    if (scroll && showActivity && activityRef.current) {
      activityRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
      setScroll(false);
    }
  }, [showActivity, scroll]);

  useEffect(() => {
    if (scroll && showOpportunityInfo && opportunityRef.current) {
      opportunityRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
      setScroll(false);
    }
  }, [showOpportunityInfo, scroll]);

  const showExitModal = () => {
    showModal({
      // exit modal with suggested actions
      newStyle: true,
      className: 'max-w-md',
      title: 'What do you want to do next?',
      children: (
        <HamsterExitModal
          hideModal={hideModal}
          pricingFlow={pricingFlow}
          opportunity={opportunity!}
          user={props.user}
          pageConfig={pageConfig}
        />
      ),
    });
  };
  const navigate = useNavigate();

  useEffect(() => {
    // Calculate approvals needed
    // Assumes you have pageConfig.hasApprovals
    async function calculate() {
      const response = await api.get(
        `approvals/flows/calculate/fields?pricingFlowId=${pricingFlow.id}&modelType=${modelType}`,
      );
      if (response.status !== 200) {
        console.error('Failed to calculate approvals needed');
      } else {
        // console.log(response.data);
        setApprovalsNeeded(response.data);
      }
    }
    calculate();
  }, [pricingFlow]);

  return (
    <div>
      {props.viewMode !== 'group' && (
        <HeaderBreadcrumbs
          steps={[
            {
              label: (
                <span className="flex flex-row gap-1 items-center">
                  <CalculatorIcon className="hidden sm:block w-5" />
                  Opportunities
                </span>
              ),
              onClick: () => {
                navigate('/app/opportunity');
              },
            },
            {
              label: `${pricingFlow.opportunity.sfdcOpportunityName}`,
              onClick: () => {
                navigate(
                  `/app/opportunity/${pricingFlow.opportunity.sfdcOpportunityId}`,
                );
              },
            },
            {
              label: getNameForPricingFlow(
                pricingFlow,
                pricingFlow.opportunity.pricingFlows,
              ),
              onClick: () => {},
            },
          ]}
        />
      )}
      {/* Header */}
      <div
        className={classNames(
          'flex flex-col gap-4 border-b border-gray-200 px-2 py-4 md:px-6 md:py-4 lg:flex-row lg:justify-between',
        )}
      >
        <div className="flex flex-row items-center gap-0 md:gap-2">
          {/* Currency icon */}
          <div className="hidden md:blockh-12 w-12">
            <div className="absolute flex h-12 w-12 items-center justify-center rounded-full border border-gray-200 bg-white shadow">
              <DocumentTextIcon className="h-5 w-5" aria-hidden="true" />
            </div>
          </div>

          <div className="flex flex-col">
            <EditableTitle
              title={getNameForPricingFlow(
                pricingFlow,
                pricingFlow.opportunity.pricingFlows,
              )}
              updateTitle={(title: string) => {
                updateFlow({ ...pricingFlow, name: title }, false);
              }}
              disabled={!editMode}
              className="max-w-prose"
            />
            <p className="text-sm text-gray-600 pl-2">
              Configure terms and products
            </p>
          </div>
        </div>
        <div className="flex flex-row items-center gap-4">
          <Button
            color="white"
            label={
              showOpportunityInfo
                ? 'Hide opportunity info'
                : 'Show opportunity info'
            }
            onClick={() => {
              setShowOpportunityInfo(!showOpportunityInfo);
              setScroll(!showOpportunityInfo);
            }}
          />
          <Button
            color="white"
            label={showActivity ? 'Hide activity' : 'Show activity'}
            onClick={() => {
              setShowActivity(!showActivity);
              setScroll(!showActivity);
            }}
          />
        </div>
      </div>
      {/* Warning about viewing a snapshot */}
      {modelType === 'pricingFlowSnapshot' && (
        <div className="border-b border-gray-200 px-8 py-4 bg-fuchsia-50 flex items-center gap-x-4 text-gray-700 text-sm">
          <span>
            Viewing a snapshot taken {dayjs(pricingFlow.createdAt).fromNow()}
          </span>
          <Button
            color="white"
            onClick={() => {
              navigate(
                `/app/opportunity/${pricingFlow.opportunity.sfdcOpportunityId}/pricingFlow/${pricingFlow.originalPricingFlowId}`,
              );
            }}
          >
            Go to current version
          </Button>
        </div>
      )}
      {/* Approval section */}
      <div className="border-b border-gray-200 p-2">
        <PricingFlowList
          pricingFlows={[pricingFlow]}
          modelType={modelType}
          user={props.user}
          hasApprovals
          hideName
          contextEditableMode={editMode ? 'default' : 'no-edit'}
          pageConfig={pageConfig}
        />
      </div>
      {/* Page contents */}
      <div className="flex flex-col xl:flex-row overflow-hidden">
        <div className="px-0 md:px-6 px-0 md:py-4 flex-1 min-w-0">
          <CollapsibleSection title="TERMS">
            <HamsterTermsSection approvalsNeeded={approvalsNeeded} />
          </CollapsibleSection>
          <CollapsibleSection title="PRICING">
            <HamsterQuoteTable approvalsNeeded={approvalsNeeded} />
          </CollapsibleSection>
          <div className="md:hidden mt-6">
            <CollapsibleSection title="EST. REVENUE">
              <div className="overflow-y-auto flex justify-start md:justify-end">
                <HamsterAnnualRevenueTable />
              </div>
            </CollapsibleSection>
          </div>
          <div className="overflow-y-auto justify-start md:justify-end mt-6 hidden md:flex">
            <HamsterAnnualRevenueTable />
          </div>

          <div className="xl:h-64" />
        </div>
        <div
          className={`transform transition-all duration-300 ease-in-out md:px-4 mt-6 md:mt-0 mb-24 md:mb-64 xl:py-2 xl:pr-2 xl:pl-0 overflow-hidden min-w-0 flex flex-col gap-y-2
        ${showSidePanel ? 'w-full xl:flex-none xl:basis-[460px] opacity-100' : 'w-0 opacity-0'}`}
        >
          <div ref={opportunityRef} className="flex flex-col gap-y-2">
            {showOpportunityInfo && opportunity && (
              <>
                <Context
                  opportunity={opportunity}
                  pageConfig={pageConfig}
                  user={user}
                />
                <OpportunityInfo
                  opportunity={opportunity}
                  pageConfig={pageConfig}
                  titleOverride="Opportunity Overview"
                  user={user}
                />
              </>
            )}
          </div>
          <div ref={activityRef}>
            {showActivity && (
              <PricingFlowActivity
                pricingFlow={pricingFlow}
                user={props.user}
              />
            )}
          </div>
        </div>
      </div>
      {!(props.viewMode === 'group') &&
        user.permissions.includes('edit_pricing_flow') && (
          <BottomBar
            primaryButtonProps={{
              label: 'Finish',
              successLabel: 'Finish',
              errorLabel: 'Error',
              onClick: async () => {
                showExitModal();
                return 'idle';
              },
            }}
          />
        )}
    </div>
  );
}

function shouldCombineActivities(
  prev: SystemActivity,
  current: SystemActivity,
) {
  const oneHour = 60 * 60 * 1000;
  return (
    current.text?.toString().includes('edited') &&
    current.text?.toString().includes('context') &&
    prev.text === current.text &&
    prev.user === current.user &&
    Math.abs(
      new Date(prev.timestamp).getTime() -
        new Date(current.timestamp).getTime(),
    ) <= oneHour
  );
}

export async function getPricingFlowActivities(params: {
  pricingFlow: PricingFlowCommon;
  user: User;
  includeComments?: boolean;
  parentTag?: string;
}): Promise<Activity[]> {
  const { pricingFlow, user, includeComments, parentTag } = params;
  let activities: Activity[] = [];
  try {
    const allUsers: User[] = (await api.get('users')).data;
    // Fetch created event
    const createdByUser = allUsers.find(
      (u) => u.id === pricingFlow.createdByUserId,
    );
    if (isNil(createdByUser)) {
      datadogRum.addError(
        new Error(`Did not find user for id ${pricingFlow.createdByUserId}`),
      );
    }
    activities.push({
      type: 'system',
      text: 'created quote',
      user: createdByUser,
      timestamp: pricingFlow.createdAt,
      parentTag,
      icon: 'plus',
    });

    // Fetch analytics events for context changes and sfdc updates
    const pricingFlowSnapshots: PricingFlowCommon[] = (
      await api.get(`pricingFlows/${pricingFlow.id}/snapshots`)
    ).data.sort(
      (a: PricingFlowCommon, b: PricingFlowCommon) =>
        new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
    );
    const opportunitySnapshots: OpportunityCommon[] = (
      await api.get(`opportunities/${pricingFlow.opportunity.id}/snapshots`)
    ).data.sort(
      (a: OpportunityCommon, b: OpportunityCommon) =>
        new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
    );
    for (let i = 1; i < pricingFlowSnapshots.length; i++) {
      const snapshot = pricingFlowSnapshots[i];
      const prevSnapshot = pricingFlowSnapshots[i - 1];
      const snapshotUser = allUsers.find(
        (u) => u.id === snapshot.lastEditedByUserId,
      );
      if (isNil(snapshotUser)) {
        datadogRum.addError(
          new Error(`Did not find user for id ${snapshot.lastEditedByUserId}`),
        );
        continue;
      }
      if (
        !_.isEqual(snapshot.additionalData, prevSnapshot.additionalData) ||
        !_.isEqual(snapshot.products, prevSnapshot.products)
      ) {
        const activity: SystemActivity = {
          type: 'system',
          icon: 'pencil-square',
          text: `edited ${pricingFlow.name}`,
          user: snapshotUser,
          timestamp: snapshot.createdAt,
        };
        const lastActivity = _.last(activities);
        if (
          !isNil(lastActivity) &&
          isSystemActivity(lastActivity) &&
          shouldCombineActivities(lastActivity, activity)
        ) {
          activities[activities.length - 1] = activity;
        } else {
          activities.push(activity);
        }
      }
      if (snapshot.context !== prevSnapshot.context) {
        const activity: SystemActivity = {
          type: 'system' as const,
          icon: 'pencil-square',
          text: `edited context on ${pricingFlow.name}`,
          user: snapshotUser,
          timestamp: snapshot.createdAt,
        };
        const lastActivity = _.last(activities);
        if (
          !isNil(lastActivity) &&
          isSystemActivity(lastActivity) &&
          shouldCombineActivities(lastActivity, activity)
        ) {
          // Replace the last activity with the current one
          activities[activities.length - 1] = activity;
        } else {
          activities.push(activity);
        }
      }
    }
    for (let i = 1; i < opportunitySnapshots.length; i++) {
      const snapshot = opportunitySnapshots[i];
      const prevSnapshot = opportunitySnapshots[i - 1];
      const snapshotUser = allUsers.find(
        (u) => u.id === snapshot.lastEditedByUserId,
      );
      if (isNil(snapshotUser)) {
        datadogRum.addError(
          new Error(`Did not find user for id ${snapshot.lastEditedByUserId}`),
        );
        continue;
      }
      if (snapshot.context !== prevSnapshot.context) {
        const activity: SystemActivity = {
          type: 'system' as const,
          icon: 'pencil-square',
          text: `edited opportunity context`,
          user: snapshotUser,
          timestamp: snapshot.createdAt,
        };
        const lastActivity = _.last(activities);
        if (
          !isNil(lastActivity) &&
          isSystemActivity(lastActivity) &&
          shouldCombineActivities(lastActivity, activity)
        ) {
          // Replace the last activity with the current one
          activities[activities.length - 1] = activity;
        } else {
          activities.push(activity);
        }
      }
      if (!_.isEqual(snapshot.opportunityData, prevSnapshot.opportunityData)) {
        activities.push({
          type: 'system' as const,
          icon: 'arrow-down-tray',
          text: `updated SFDC data`,
          user: snapshotUser,
          timestamp: snapshot.createdAt,
        });
      }
    }

    // Fetch comments if needed
    if (includeComments) {
      const comments = (
        await api.get(
          `comments/threads/pricingFlow/${pricingFlow.originalPricingFlowId}`,
        )
      ).data;
      activities = activities.concat(
        comments.map((c: CommentThread) => ({ ...c, parentTag })),
      );
    }

    // Fetch approval requests and approval actions
    const approvalRequests = (
      await api.get(
        `approvals/requests?pricingFlowId=${pricingFlow.originalPricingFlowId}`,
      )
    ).data as ApprovalRequest[];
    console.log('approvalRequests: ', approvalRequests);
    for (const request of approvalRequests) {
      // created
      activities.push({
        type: 'system',
        text: (
          <>
            submitted an{' '}
            <Link
              to={`/app/opportunity/${pricingFlow.opportunity.sfdcOpportunityId}/pricingFlowSnapshot/${request.pricingFlowSnapshotId}`}
              className="text-fuchsia-900 hover:underline"
            >
              approval request
            </Link>
          </>
        ),
        user: request.createdByUser,
        timestamp: request.createdAt,
        parentTag,
        icon: 'send',
      });
      // actions
      for (const action of request.approvalActions) {
        const { pastTense, icon } = actionToPastTense(action.action);
        activities.push({
          type: 'system',
          text: pastTense,
          user: action.user,
          timestamp: action.createdAt,
          parentTag,
          icon,
        });
        if (action.recalledAt) {
          activities.push({
            type: 'system',
            text: `recalled ${action.action === 'APPROVE' ? 'approval' : 'rejection'}`,
            user: action.user,
            timestamp: action.recalledAt,
            parentTag,
            icon: 'backspace',
          });
        }
      }
      // if recalled
      if (request.recalledAt) {
        activities.push({
          type: 'system',
          text: `recalled approval request`,
          user: request.createdByUser,
          timestamp: request.recalledAt,
          parentTag,
          icon: 'arrow-uturn-left',
        });
      }
    }
  } catch (error) {
    datadogRum.addError(
      `Failed to fetch created by user for pricing flow ${pricingFlow.id}`,
    );
    console.error('Failed to fetch created by user: ', error);
  }
  return activities;
}

function PricingFlowActivity({
  pricingFlow,
  user,
}: {
  pricingFlow: HamsterPricingFlow;
  user: User;
}) {
  const [systemActivities, setSystemActivities] = useState<Activity[]>([]);
  useEffect(() => {
    async function fetchActivities() {
      // Fetch created by user
      const activities = await getPricingFlowActivities({ pricingFlow, user });
      setSystemActivities(activities);
    }
    fetchActivities();
  }, []);

  return (
    <ActivitySection
      parentType="pricingFlow"
      parentId={pricingFlow.id}
      systemActivities={systemActivities}
      user={user}
    />
  );
}
