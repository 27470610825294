import { classNames } from 'src/dashboard/App';

export type BadgeColor =
  | 'gray'
  | 'red'
  | 'orange'
  | 'yellow'
  | 'green'
  | 'blue'
  | 'indigo'
  | 'purple'
  | 'pink'
  | 'none';

const colorMapping = {
  gray: 'bg-gray-50 text-gray-700',
  red: 'bg-red-50 text-red-700',
  orange: 'bg-orange-50 text-orange-700',
  yellow: 'bg-yellow-50 text-yellow-700',
  green: 'bg-green-50 text-green-700',
  blue: 'bg-blue-50 text-blue-700',
  indigo: 'bg-indigo-50 text-indigo-700',
  purple: 'bg-purple-50 text-purple-700',
  pink: 'bg-pink-50 text-pink-700',
  none: '',
};

export default function Badge(props: {
  children: React.ReactNode;
  color: BadgeColor;
  className?: string;
  size?: 'small' | 'medium';
  style?: React.CSSProperties;
}) {
  return (
    <span
      className={classNames(
        colorMapping[props.color],
        'inline-flex items-center rounded-full text-xs font-medium',
        props.size === 'small' ? 'px-1.5 py-0.5' : 'px-2 py-1',
        props.className ?? '',
      )}
      style={props.style}
    >
      {props.children}
    </span>
  );
}
