import { HamsterOpportunityData } from '../Hamster/hamster_types';
import { PricingFlowCommon, PricingFlowType } from '../types';
import { PenguinOpportunityData, PenguinProduct } from './penguin_types';

const pricingCurveRegistry: Record<
  string,
  (pricingFlow: PricingFlowCommon) => boolean
> = {
  default: () => {
    return true;
  },
  testCurve_20240901: (pricingFlow) => {
    // corresponds to opp id 006UV000003tGwtYAE in penguin prod
    return pricingFlow.id === '06212f70-6239-4c2f-b5a1-f0a6d0a62f4c';
  },
  ['identity_and_assets_5_2024-11-17']: (pricingFlow) => {
    const now = new Date();
    // 8 am UTC on 2024/11/18, month is 0-indexed so november is "10"
    const experimentStartDate = new Date(Date.UTC(2024, 10, 18, 8, 0, 0));
    return (
      pricingFlow.type === PricingFlowType.PENGUIN && now > experimentStartDate
    );
  },
  hamster_midmarket: (pricingFlow) => {
    return (
      pricingFlow.type === PricingFlowType.HAMSTER &&
      (pricingFlow.opportunity.opportunityData as HamsterOpportunityData)
        ?.Segment === 'Mid-Market'
    );
  },
  signal_3x_for_crypto: (pricingFlow) => {
    const oppData = pricingFlow.opportunity.opportunityData as
      | PenguinOpportunityData
      | undefined;
    const products = pricingFlow.products as unknown as
      | PenguinProduct[]
      | undefined;
    return (
      pricingFlow.type === PricingFlowType.PENGUIN &&
      (oppData?.Opportunity_GTM_Segment__c === 'Crypto' ||
        (products?.find((p) => p.name === 'Signal')?.transactionSize?.value ??
          0) > 1000)
    );
  },
};

export default pricingCurveRegistry;
