import { Dialog, Transition } from '@headlessui/react';
import { Bars3Icon, UserIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { User } from 'src/types';
import { classNames, Navigation, NavigationPage } from '../dashboard/App';
import dealopsLogo from '../marketing-site/assets/Logo_mobile.svg';
import OverflowMenu from './OverflowMenu';

type SidebarMobileProps = {
  sidebarOpen: boolean;
  setSidebarOpen: (open: boolean) => void;
  tab: NavigationPage;
  navigation: Navigation;
  user?: User | null;
  onLogout?: () => void;
};

export default function SidebarMobile(props: SidebarMobileProps) {
  const { sidebarOpen, setSidebarOpen, tab, navigation, user, onLogout } =
    props;

  return (
    <>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50 lg:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-900/80" />
          </Transition.Child>

          <div className="fixed inset-0 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                    <button
                      type="button"
                      className="-m-2.5 p-2.5"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
                {/* Sidebar component, swap this element with another sidebar if you like */}
                <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-2">
                  <div className="flex h-16 shrink-0 items-center">
                    <img
                      className="h-8 w-auto"
                      src={dealopsLogo}
                      alt="Dealops"
                    />
                  </div>
                  <nav className="flex flex-1 flex-col">
                    <ul className="flex flex-1 flex-col gap-y-7">
                      <li>
                        <ul className="-mx-2 space-y-1">
                          {Object.keys(navigation).map((name) => {
                            const item = navigation[name];
                            if (item.hidden) return null;
                            return (
                              <li key={name}>
                                <Link
                                  to={item.to}
                                  className={classNames(
                                    tab.name === item.name
                                      ? 'bg-gray-50 text-fuchsia-900'
                                      : 'text-gray-700 hover:bg-gray-50 hover:text-fuchsia-900',
                                    'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6',
                                  )}
                                >
                                  {item.icon && (
                                    <item.icon
                                      className={classNames(
                                        tab.name === item.name
                                          ? 'text-fuchsia-900'
                                          : 'text-gray-400 group-hover:text-fuchsia-900',
                                        'h-6 w-6 shrink-0',
                                      )}
                                      aria-hidden="true"
                                    />
                                  )}
                                  {item.name}
                                </Link>
                              </li>
                            );
                          })}
                        </ul>
                      </li>
                    </ul>
                  </nav>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
      <div className="top-0 z-50 flex h-12 shrink-0 items-center gap-x-6 bg-white px-4 shadow-sm sm:px-6 lg:hidden border border-b border-slate-200">
        <button
          type="button"
          className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
          onClick={() => setSidebarOpen(true)}
        >
          <span className="sr-only">Open sidebar</span>
          <Bars3Icon className="h-6 w-6" aria-hidden="true" />
        </button>
        <div className="flex-1 text-sm font-semibold leading-6 text-gray-900">
          Dealops
        </div>
        {user && onLogout && (
          <OverflowMenu
            menuItems={[
              {
                name: 'Logout',
                onClick: onLogout,
              },
            ]}
            placement="bottom-end"
          >
            <UserIcon
              className={classNames('text-gray-400', 'h-5 w-5 shrink-0')}
              aria-hidden="true"
            />
          </OverflowMenu>
        )}
      </div>
    </>
  );
}
