import { AlpacaPricingFlow, AlpacaProduct } from './Alpaca/alpaca_types';
import { HamsterPricingFlow } from './Hamster/hamster_types';
import { PenguinPricingFlow, PenguinProduct } from './Penguin/penguin_types';
import { PricingFlowOrSnapshotForNavigation } from './QuoteOptionsSection';

export type PricingFlow =
  | DealopsPricingFlow
  | PenguinPricingFlow
  | AlpacaPricingFlow
  | HamsterPricingFlow;

export enum PricingFlowType {
  DEALOPS = 'DEALOPS',
  PENGUIN = 'PENGUIN',
  COMPLEX_DEMO = 'COMPLEX_DEMO',
  ALPACA = 'ALPACA',
  HAMSTER = 'HAMSTER',
  HAMSTER_FOR_DEMO = 'HAMSTER_FOR_DEMO',
}

// Synced with server/src/types/pricing_flow_common.ts
export enum PricingFlowStage {
  NOT_STARTED = 'NOT_STARTED',
  ADD_PRODUCTS = 'ADD_PRODUCTS',
  CALCULATE_PRICE = 'CALCULATE_PRICE',
}

// Synced with server/src/types/pricing_flow_common.ts
// these are the fields on the pricingFlow the user can edit and update
export type PricingFlowMutableFields = {
  name?: string;
  additionalData: unknown;
  products:
    | (
        | (ProductCommon & unknown)
        | DealopsProductWithVolume
        | PenguinProduct
        | AlpacaProduct
      )[]
    | null;
  manualQuote: unknown;
  finalQuote: unknown;
  stage: PricingFlowStage;
  cloneFromPricingFlowId?: string;
  // Not directly mutated by the user, but actions they take can affect which
  // pricing curves are in use on the pricing flow
  currentPricingCurves: unknown;
  lastEditedByUserId: string;
  context?: string;
};
export const PRICING_FLOW_MUTABLE_KEYS: [
  'products',
  'additionalData',
  'manualQuote',
  'finalQuote',
  'stage',
  'currentPricingCurves',
  'name',
  'lastEditedByUserId',
  'context',
] = [
  'products',
  'additionalData',
  'manualQuote',
  'finalQuote',
  'stage',
  'currentPricingCurves',
  'name',
  'lastEditedByUserId',
  'context',
];

export type OpportunityCommon = {
  id: string;
  updatedAt: string;
  sfdcInstanceUrl: string;
  sfdcOpportunityId: string;
  sfdcOpportunityName: string | null;
  opportunityData: unknown;
  context: string | null;
  pricingFlows: PricingFlowOrSnapshotForNavigation[];
  lastEditedByUserId: string;
  createdAt: string;
};

// Synced with server/src/types/pricing_flow_common.ts
// these fields are supplied by the server and can't be directly updated
export type PricingFlowReadonlyFields = Readonly<{
  id: string;
  // PricingFlow can either be a real PricingFlow or a PricingFlowSnapshot
  // slightly reconfigured to look like a pricing flow. In the latter case,
  // originalPricingFlowId points at the original pricing flow when needed.
  // Otherwise, this is the same as id
  originalPricingFlowId: string;
  organizationId: string;
  userId: string;
  createdAt: string;
  updatedAt: string;
  createdByUserId: string;

  type: PricingFlowType;
  // SFDC info
  opportunity: OpportunityCommon;
  sfdcQuoteId: string | null;

  pricingSheet: string;

  recommendedQuote: unknown;
  approvalLevels: unknown;
  pricingSheetData: unknown;
}>;

// Synced with server/src/types/pricing_flow_common.ts
export type PricingFlowCommon = PricingFlowMutableFields &
  PricingFlowReadonlyFields;

// Synced with server/src/types/pricing_flow_common.ts
export type ProductCommon = {
  id: string;
  name: string;
  volume: number; // @TODO: If we change this to be a CurrencyValue, we will need to update these functions (search "@TODO(CurrencyValueVolumes)")
};

/*******************************
 * DEALOPS PRICING FLOW
 ******************************/
export type DealopsPricingFlow = PricingFlowCommon & {
  // Quote data
  type: PricingFlowType.DEALOPS;
  opportunityData: DealopsOpportunityData;
  additionalData: DealopsAdditionalData;
  products: DealopsProduct[];
  recommendedQuote: DealopsRecommendedQuote;
  manualQuote: DealopsManualQuote;
  finalQuote: DealopsManualQuote;
  approvalLevels: DealopsApprovalLevels;
  pricingSheetData: DealopsProductPrices;
};

export type DealopsOpportunityData = {
  User_Name: string | null;
  Account_Industry: string | null;
  Account_BillingCountry: string | null;
  Opportunity_StageName: string | null;
  Opportunity_Probability: number | null;
  Opportunity_ExpectedRevenue: number | null;
  Opportunity_MainCompetitors__c: string | null;
};

export type DealopsAdditionalData = {
  monthlyBudget: number | null;
  contractLength: number | null;
  marketingRights: boolean | null;
  competitors: string[] | null;
};

export type DealopsProduct = ProductCommon & {
  volume: number;
  transactionSize?: number;
  rampUpType?: RampUpTypeIdentifier;
  customRampUp?: number[];
  linearRampUpConfig?: { start: number; months: number };
  fixedRampUp?: number;
};

export type DealopsRecommendedQuote = {
  monthlyMinimum: number;
  products: { [key: string]: number };
  productPricingLogs?: { [key: string]: [string] };
};

export type DealopsManualQuote = {
  monthlyMinimum?: number;
  products?: { [key: string]: number };
};

export type DealopsApprovalLevels = { [key: string]: number };

export type DealopsProductPrice = {
  id: string;
  skuGroup: string;
  parentProduct: string;
  name: any;
  skuSubgroup: string;
  financeApproval: number;
  selfServe: number;
  unitDefinition: string;
  monthlyMinimumTiers: {
    min_spend: number;
    currency: string;
    recommendedPricing: number;
    level_1: number;
    level_2: number;
    level_3: number;
    level_4: number;
  };
  priceType: string;
};
export type DealopsProductPrices = { [key: string]: DealopsProductPrice };

export type RampUpTypeIdentifier =
  | 'fixed'
  | 'custom'
  | 'linear'
  | 'linear_quarterly';

export interface Tier {
  minimum: number;
  price: number;
}

// ##PricingCurveType
// This type mirrors the prisma schema. Find the corresponding # comment to see
// where the data is sourced from
export type PricingCurve = {
  id: string;
  productId: string;
  name: string;
  isActive: boolean;
  condition: string;
  priority: number;
  pricingInformation: unknown;
};

// Synced with server-side types
export type Subcategory = {
  name: string;
  isActive: boolean;
  products: string[];
};

// Synced with server/src/types/demo_dealops.ts
export enum PlatformType {
  UNSET = '',
  SELF_HOSTED = 'Self hosted',
  CLOUD = 'Cloud',
  MANAGED_CLOUD = 'Managed cloud',
  SELF_HOSTED_AND_AIR_GAPPED = 'Self hosted and air gapped',
}

// Synced with server/src/types/demo_dealops.ts
export enum Segment {
  UNSET = '',
  ENTERPRISE = 'enterprise',
  BUSINESS = 'business',
}

// Synced with server/src/types/demo_dealops.ts
export enum ProductTab {
  LICENSE_TYPES = 'License types',
  PLATFORM_FEES = 'Platform fees',
  ADD_ON_FEATURES = 'Add-on features',
  ADD_ON_SERVICES = 'Add-on services',
}

export type SalesforceProduct = {
  productFamily: string;
  productType: string;
  productName: string;
  listPrice: string;
  minimumSalePrice: string;
  productId: string;
};
export type SalesforceProductWithVolume = SalesforceProduct & {
  volume?: number;
};

// Synced with server/src/types/demo_dealops.ts
export type ProductMapping = {
  volumeMin: number | null;
  volumeMax: number | null;
  salesforceProductId: string;
};

// Synced with server/src/types/demo_dealops.ts
export type DealopsProductDefinition = {
  id: string;
  tab: ProductTab;
  platformType: PlatformType;
  segment: Segment;
  name: string;
  productId: string;
  productMapping: ProductMapping[];
  defaultVolume: number;
};

// Synced with server/src/types/demo_dealops.ts
export type DealopsProductWithVolume = DealopsProductDefinition & {
  volume?: number;
};
