import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';
import dayjs from 'dayjs';
import { isNil } from 'lodash';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import Button from 'src/components/Button';
import { RadioBoxFields } from 'src/components/Fields';
import { useModal } from 'src/components/Modal';
import { classNames } from 'src/dashboard/App';
import { OpportunityDetailPageConfig } from 'src/dashboard/Opportunity/OpportunityDetailPage';
import { PricingFlowGroupDisplay } from 'src/dashboard/PricingFlowGroup/PricingFlowGroup';
import { User } from 'src/types';
import { getNameForPricingFlow } from 'src/utils';
import { Opportunity, PricingFlowGroup } from '../../Opportunity/types';
import { PricingFlowOrSnapshotForNavigation } from '../QuoteOptionsSection';
import { OpportunityCommon } from '../types';
import AskForApprovalModal from './ApprovalModal';

interface PricingFlowRowProps {
  opportunity: OpportunityCommon;
  pricingFlow: PricingFlowOrSnapshotForNavigation;
  isChecked: boolean;
  setIsChecked: (newIsChecked: boolean) => void;
}
export function PricingFlowRow(props: PricingFlowRowProps) {
  const { isChecked, setIsChecked, pricingFlow, opportunity } = props;
  return (
    <label
      key={pricingFlow.id}
      htmlFor={pricingFlow.id}
      className={classNames(
        'flex justify-between items-center border rounded-lg p-2 mt-2',
        isChecked ? 'border-fuchsia-900' : 'border-gray-200',
      )}
    >
      {/* left side */}
      <div className="flex items-center gap-x-4">
        <input
          className="rounded-sm border-gray-300 text-fuchsia-900 focus:ring-fuchsia-900 focus:border-fuchsia-900 pl-1"
          id={pricingFlow.id}
          type="checkbox"
          checked={isChecked}
          onChange={() => setIsChecked(!isChecked)}
        />
        <div className="flex flex-col">
          <span className="font-medium text-sm text-gray-950">
            {getNameForPricingFlow(pricingFlow, opportunity.pricingFlows)}
          </span>
          <span className="text-xs text-gray-600 italic">
            Updated {dayjs(pricingFlow.updatedAt).fromNow()}
          </span>
        </div>
      </div>
      {/* right side */}
      <div className="flex items-center pr-2">
        <Link
          to={`/app/opportunity/${opportunity.sfdcOpportunityId}/pricingflow/${pricingFlow.id}`}
          target="_blank"
          relative="path"
          className="text-sm font-semibold text-fuchsia-900"
        >
          <ArrowTopRightOnSquareIcon
            className="inline-block h-5 w-5"
            aria-hidden="true"
          />
        </Link>
      </div>
    </label>
  );
}

function Footer(props: { onNext: () => void }) {
  const { hideModal } = useModal();
  return (
    <div className="mt-4 shrink-0 border-t border-100">
      <div className="flex flex-row justify-between p-4 gap-4 sm:flex-row-reverse">
        <Button
          color="primary"
          onClick={props.onNext}
          className="flex-1"
          label="Next"
        />
        <Button
          color="white"
          onClick={() => hideModal()}
          className="flex-1"
          label="Cancel"
        />
      </div>
    </div>
  );
}

function ModalContentContainer(props: {
  content: React.ReactNode;
  onNext: () => void;
}) {
  const { content, onNext } = props;
  return (
    <div className="min-h-[540px] h-[75vh] flex-1 flex flex-col overflow-hidden">
      {/* Scrollable Options Section */}
      <div className="overflow-y-auto flex-1 overflow-hidden p-4 flex-1">
        <div className="space-y-2">{content}</div>
      </div>

      <Footer onNext={onNext} />
    </div>
  );
}

function CreateGroupDialog(props: {
  newPricingFlow: PricingFlowOrSnapshotForNavigation;
  opportunity: Opportunity;
  user: User;
  pageConfig: OpportunityDetailPageConfig;
}) {
  const { newPricingFlow, opportunity, user, pageConfig } = props;
  const [selectedPricingFlows, setSelectedPricingFlows] = useState<string[]>([
    newPricingFlow.id,
  ]);
  const { showModal, hideModal } = useModal();
  const content = (
    <>
      {opportunity.pricingFlows.map((p) => {
        return (
          <PricingFlowRow
            key={p.id}
            opportunity={opportunity}
            pricingFlow={p}
            isChecked={
              newPricingFlow.id === p.id || selectedPricingFlows.includes(p.id)
            }
            setIsChecked={(newIsChecked) => {
              if (newPricingFlow.id === p.id) return;
              if (newIsChecked) {
                setSelectedPricingFlows([...selectedPricingFlows, p.id]);
              } else {
                setSelectedPricingFlows(
                  selectedPricingFlows.filter((id) => id !== p.id),
                );
              }
            }}
          />
        );
      })}
    </>
  );
  function submitSelectedQuotes() {
    showModal({
      newStyle: true,
      title: 'Ask for approval',
      children: (
        <AskForApprovalModal
          hideModal={hideModal}
          pricingFlows={selectedPricingFlows
            .map((id) => opportunity.pricingFlows.find((p) => p.id === id))
            .filter((p) => p !== undefined)}
          forceCreateGroup={false}
          user={user}
          opportunity={opportunity}
          pageConfig={pageConfig}
        />
      ),
    });
  }
  return (
    <ModalContentContainer content={content} onNext={submitSelectedQuotes} />
  );
}
export type ApprovalModalMode = 'GROUP_ONLY' | 'GROUP_OR_INDIVIDUAL';
interface ApprovalOptionsModalProps {
  pricingFlow: PricingFlowOrSnapshotForNavigation;
  opportunity: Opportunity;
  user: User;
  mode: ApprovalModalMode;
  pageConfig: OpportunityDetailPageConfig;
  activeGroups: PricingFlowGroup[];
}
export default function ApprovalOptionsModal(props: ApprovalOptionsModalProps) {
  const { opportunity, pricingFlow, user, mode, pageConfig, activeGroups } =
    props;
  type ActionOptions = 'only_this_quote' | 'add_to_group';
  const [selectedAction, setSelectedAction] =
    useState<ActionOptions>('add_to_group');

  const { showModal, hideModal } = useModal();

  function submitOnlyThisQuote(forceCreateGroup: boolean) {
    showModal({
      newStyle: true,
      title: 'Ask for approval',
      children: (
        <AskForApprovalModal
          hideModal={hideModal}
          pricingFlows={[pricingFlow]}
          forceCreateGroup={forceCreateGroup}
          user={user}
          opportunity={opportunity}
          pageConfig={pageConfig}
        />
      ),
    });
  }

  if (activeGroups.length === 0) {
    if (opportunity.pricingFlows.length > 1) {
      return (
        <CreateGroupDialog
          newPricingFlow={pricingFlow}
          opportunity={opportunity}
          user={user}
          pageConfig={pageConfig}
        />
      );
    } else {
      submitOnlyThisQuote(mode === 'GROUP_ONLY');
    }
  }
  const activeGroupsWithThisQuote = activeGroups.filter((group) => {
    return group.pricingFlowOnGroup.some(
      (p) => p.pricingFlowId === props.pricingFlow.id,
    );
  });
  const activeGroup = activeGroupsWithThisQuote[0] ?? activeGroups[0];
  if (isNil(activeGroup)) {
    return null;
  }
  const isPricingFlowInActiveGroup = activeGroupsWithThisQuote.length > 0;
  const renderTitle = (title: string) => {
    return <span className="text-sm font-medium text-gray-800">{title}</span>;
  };
  const content = (
    <RadioBoxFields<ActionOptions>
      checked={selectedAction}
      name={'submit_for_approval_action'}
      onSelectOption={(action) => {
        setSelectedAction(action);
      }}
      radioBoxConfigs={[
        {
          title: renderTitle('Submit this quote only'),
          value: 'only_this_quote',
          description: null,
          disabled: mode === 'GROUP_ONLY',
        },
        {
          title: renderTitle(
            isPricingFlowInActiveGroup
              ? 'Submit this quote with its group'
              : 'Add quote to an existing group',
          ),
          value: 'add_to_group',
          description: (
            <PricingFlowGroupDisplay
              group={activeGroup}
              user={user}
              shouldShowQuotes={true}
              pageConfig={pageConfig}
              isApprovalModalGroupView={true}
            />
          ),
          disabled: false,
        },
      ]}
    />
  );
  return (
    <ModalContentContainer
      content={content}
      onNext={() => {
        const groupPricingFlows = activeGroup.pricingFlowOnGroup.map(
          (pfog) => pfog.pricingFlow,
        );
        const { pricingFlows, group } = (() => {
          switch (selectedAction) {
            case 'only_this_quote':
              return {
                pricingFlows: [pricingFlow],
                group: undefined,
              };
            case 'add_to_group':
              return {
                pricingFlows: groupPricingFlows.some(
                  (pf) => pf.id === pricingFlow.id,
                )
                  ? groupPricingFlows
                  : [pricingFlow, ...groupPricingFlows],
                group: activeGroup,
              };
          }
        })();
        showModal({
          newStyle: true,
          title: 'Review & Submit Approval Request',
          subtitle: 'Add context to help speed up the approval process',
          children: (
            <AskForApprovalModal
              hideModal={hideModal}
              pricingFlows={pricingFlows}
              group={group}
              forceCreateGroup={false}
              user={user}
              opportunity={opportunity}
              pageConfig={pageConfig}
            />
          ),
        });
      }}
    />
  );
}
