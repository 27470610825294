import buildingsIcon from '../images/buildings-icon.svg';
import calculatorImage from '../images/calculator.svg';
import { Organization, User } from '../types';

interface DashboardHomeProps {
  user: User;
  organization: Organization;
}

export default function DashboardHome(props: DashboardHomeProps) {
  const { user, organization } = props;

  const firstName = user.name ? user.name.split(' ')[0] : 'Sales Rep';
  const organizatinName = organization?.name;

  return (
    <div className="relative flex flex-col items-center bg-repeat-x min-h-screen">
      {/* Organization header - made responsive with padding adjustments */}
      <div className="mx-4 sm:mx-8 mt-4 sm:mt-6 flex space-x-3 self-end rounded-lg border border-gray-300 px-3 sm:px-4 py-2 sm:py-3">
        <div className="overflow-hidden rounded-full">
          <img
            src={buildingsIcon}
            alt="buildings icon"
            className="h-8 w-8 sm:h-10 sm:w-10"
          />
        </div>
        <div className="self-center">
          <div className="text-xs sm:text-sm font-semibold">
            {organizatinName}
          </div>
          <div className="text-xs sm:text-sm text-gray-600">Dealops</div>
        </div>
      </div>

      {/* Grid background section - made responsive with full width */}
      <div
        className="mt-8 sm:mt-16 w-full"
        style={{
          opacity: 0.8,
          backgroundImage: `linear-gradient(rgb(200,200,200) 2px, transparent 1px), linear-gradient(to right, rgb(200,200,200) 2px, white 1px)`,
          backgroundSize: '60px 60px',
        }}
      >
        <div
          className="flex justify-center pt-4 px-4"
          style={{
            background:
              'radial-gradient(ellipse, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 70%)',
          }}
        >
          <img
            className="h-40 sm:h-60 md:h-80 w-auto"
            alt="calculator"
            src={calculatorImage}
          />
        </div>
      </div>

      {/* Welcome section - responsive text sizes */}
      <div className="mt-4 text-2xl sm:text-3xl">👋</div>
      <div className="text-2xl sm:text-3xl font-semibold text-center px-4">
        Welcome {firstName}!
      </div>
      <div className="mt-4 max-w-xl text-center text-gray-600 text-sm sm:text-base px-4">
        End-to-end pricing engine. From custom deal pricing recommendations to
        seamless CPQ integration.
      </div>

      {/* CTA Button - responsive padding and margin */}
      <a
        href="/app/opportunity"
        className="mb-6 sm:mb-8 mt-4 rounded-lg bg-fuchsia-900 px-3 py-2 text-xs sm:text-sm font-bold text-white shadow-sm hover:bg-fuchsia-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-fuchsia-900"
      >
        Price an opportunity
      </a>
    </div>
  );
}
