import { datadogRum } from '@datadog/browser-rum';
import {
  CheckIcon,
  ChevronRightIcon,
  ClockIcon,
  ExclamationTriangleIcon,
} from '@heroicons/react/24/outline';
import dayjs from 'dayjs';
import { produce } from 'immer';
import { isNil } from 'lodash';
import { useEffect, useState } from 'react';
import api from 'src/api';
import { useAnalyticsContext } from 'src/components/AnalyticsContext';
import Badge, { BadgeColor } from 'src/components/Badge';
import Button from 'src/components/Button';
import {
  CrepeDisplayReadonly,
  CrepeEditorWithAutosave,
} from 'src/components/CrepeEditor';
import { useModal } from 'src/components/Modal';
import Tooltip from 'src/components/Tooltip';
import { User } from 'src/types';
import { unreachable } from 'src/typeUtils';
import { classNames } from '../App';
import {
  getQuoteContextTemplate,
  OpportunityDetailPageConfig,
} from '../Opportunity/OpportunityDetailPage';
import NudgeButton from './NudgeButton';
import { PricingFlowModelType } from './PricingFlow';
import { PricingFlowOrSnapshotForNavigation } from './QuoteOptionsSection';
import SubmitForApprovalButton from './SubmitForApprovalButton';

// #StepRequiresPackaging
type StepStatus =
  | 'APPROVED'
  | 'PENDING' // This is the enum that's returned from the StepSnapshot's conditionForApprovalJavascript
  | 'REQUIRES_PACKAGING'; // This is a special status that is used when a step is pending and requires packaging
interface StepSnapshotWithStatus {
  id: string; // snapshot ID
  name: string;
  status: StepStatus;
  usersWithStatus: UserWithStatus[];
  color: string;
  approvalRules: {
    id: string;
    humanReadable: string;
  }[];
  requiresPackaging: boolean;
}
export interface UserWithStatus {
  id: string;
  name: string | null;
  status: UserApprovalStatus;
  requestedAt: Date | null;
}
type UserApprovalStatus = 'APPROVED' | 'REJECTED' | 'PENDING';
type ApprovalFlowStatus =
  | 'APPROVED'
  | 'PENDING'
  | 'NO_APPROVAL_NEEDED'
  | 'REQUIRES_PACKAGING';
interface ApprovalFlowWithStatuses {
  status: ApprovalFlowStatus;
  stepsWithStatus: StepSnapshotWithStatus[];
}
export type ApprovalStatusForPricingFlow = {
  currentApprovalRequest: {
    id: string;
    createdAt: string;
    recalledAt?: Date;
  } | null;
  approvalFlowWithStatuses: ApprovalFlowWithStatuses;
  submitter?: string;
};

export default function PricingFlowList(props: {
  pricingFlows: PricingFlowOrSnapshotForNavigation[];
  modelType: PricingFlowModelType;
  onClick?: (pricingFlow: PricingFlowOrSnapshotForNavigation) => void;
  user: User;
  displayApprovalFlowForDraft?: boolean;
  hasApprovals?: boolean;
  hideName?: boolean;
  listItemClassName?: string;
  contextEditableMode: 'edit-only' | 'no-edit' | 'default';
  pageConfig: OpportunityDetailPageConfig;
  isApprovalModalGroupView?: boolean;
  // Note: setPricingFlowContextMd is a means of passing the markdown from the
  // quote context crepe back up to the parent. If this is non-nil, autosaving
  // will be turned off for the markdown editor, and the parent must handle
  // writing context updates back to the database!
  pricingFlowContextMds?: Record<string, string | null>;
  setPricingFlowContextMds?: React.Dispatch<
    React.SetStateAction<Record<string, string | null>>
  >;
}) {
  const {
    pricingFlows,
    modelType,
    onClick,
    user,
    displayApprovalFlowForDraft,
    hasApprovals,
    hideName,
    listItemClassName,
    contextEditableMode,
    pageConfig,
    isApprovalModalGroupView,
    pricingFlowContextMds,
    setPricingFlowContextMds,
  } = props;
  if (isNil(pricingFlowContextMds) !== isNil(setPricingFlowContextMds)) {
    datadogRum.addError(
      new Error(
        `You must either pass both or neither of pricingFlowContextMds and
        setPricingFlowContextMds to PricingFlowList`,
      ),
    );
  }
  const [approvalStatuses, setApprovalStatuses] = useState<{
    [pricingFlowId: string]: ApprovalStatusForPricingFlow;
  }>({});
  const defaultValueForDisplay = 'No quote description';
  const templateForEditing = getQuoteContextTemplate({
    pricingFlow: pricingFlows[0],
  });
  const createAnalyticsEvent = useAnalyticsContext();

  useEffect(() => {
    hasApprovals &&
      Promise.all(
        pricingFlows.map(async (pricingFlow) => {
          const response = await (async () => {
            switch (modelType) {
              case 'pricingFlowSnapshot':
                return await api.get(
                  'approvals/flows/status?pricingFlowSnapshotId=' +
                    pricingFlow.id,
                );
              case 'pricingFlow':
              default:
                return await api.get(
                  'approvals/flows/status?pricingFlowId=' + pricingFlow.id,
                );
            }
          })();
          const data = await response.data;
          return [pricingFlow.id, data];
        }),
      ).then((data) => {
        setApprovalStatuses(Object.fromEntries(data));
      });
  }, [pricingFlows]);
  // console.log(approvalStatuses);

  const Wrapper = onClick ? 'button' : 'div';
  return (
    <>
      {pricingFlows?.map((pricingFlow) => {
        return (
          <Wrapper
            className={classNames(
              'flex flex-col gap-2 w-full bg-white px-3 py-2 rounded-xl text-left',
              listItemClassName,
              onClick
                ? 'hover:bg-neutral-100 cursor-pointer border border-slate-200 sm:border-none'
                : '',
            )}
            key={pricingFlow.id}
            onClick={onClick ? (e) => onClick(pricingFlow) : undefined}
          >
            <div className="flex flex-col md:flex-row w-full justify-between">
              {/* left side */}
              <div className="flex flex-col gap-1">
                {/* Name */}
                {!hideName && (
                  <div className="flex flex-col md:flex-row md:space-x-2 items-left md:items-center">
                    <div className="font-medium text-slate-950 text-sm">
                      {pricingFlow.name ?? 'Quote option'}
                    </div>
                    <div className="text-xs text-slate-600 italic">
                      Updated {dayjs(pricingFlow.updatedAt).fromNow()}
                    </div>
                  </div>
                )}

                {/* Approvals */}
                {hasApprovals && (
                  <ApprovalStatus
                    approvalStatus={approvalStatuses[pricingFlow.id]}
                    displayApprovalFlowForDraft={displayApprovalFlowForDraft}
                  />
                )}
              </div>

              {/* right side */}
              <div className="hidden md:flex flex-col mt-2 md:mt-0 md:flex-row items-start gap-x-2">
                {!isApprovalModalGroupView && hasApprovals && (
                  <ApprovalActionButtons
                    pricingFlow={pricingFlow}
                    modelType={modelType}
                    approvalStatus={approvalStatuses[pricingFlow.id]}
                    user={user}
                    pageConfig={pageConfig}
                  />
                )}
                {onClick && (
                  <ChevronRightIcon className="hidden md:block w-4 h-4 mt-2 mx-1" />
                )}
              </div>
            </div>
            {/* Quote Description */}
            <div className="w-full">
              <div
                className={classNames(
                  contextEditableMode === 'default' && '-mx-3 -mb-2',
                )}
              >
                {contextEditableMode === 'no-edit' ? (
                  <CrepeDisplayReadonly
                    value={pricingFlow.context}
                    placeholder={defaultValueForDisplay}
                  />
                ) : (
                  <CrepeEditorWithAutosave
                    initialValue={
                      pricingFlow.context ??
                      pricingFlowContextMds?.[pricingFlow.id] ??
                      null
                    }
                    placeholder={defaultValueForDisplay}
                    templateForEditing={templateForEditing}
                    setMarkdown={
                      isNil(setPricingFlowContextMds) ||
                      isNil(pricingFlowContextMds)
                        ? undefined
                        : (newMarkdown: string | null) => {
                            setPricingFlowContextMds((prevMarkdowns) =>
                              produce(
                                prevMarkdowns,
                                (draftPricingFlowContextMds) => {
                                  draftPricingFlowContextMds[pricingFlow.id] =
                                    newMarkdown;
                                },
                              ),
                            );
                          }
                    }
                    onMarkdownUpdated={
                      !isNil(setPricingFlowContextMds)
                        ? null
                        : async (markdown) => {
                            api.post(
                              'pricingFlows/' +
                                pricingFlow.originalPricingFlowId +
                                '/update-context',
                              { context: markdown },
                            );
                          }
                    }
                    editableMode={contextEditableMode}
                    onBlur={async () => {
                      createAnalyticsEvent({
                        name: 'pricing_flow__edited_context',
                        eventData: { pricing_flow_id: pricingFlow.id },
                      });
                      await api.post(
                        `pricingFlows/${pricingFlow.id}/snapshot`,
                        {},
                      );
                    }}
                  />
                )}
              </div>
            </div>
            <div className="flex md:hidden w-full flex-col mt-2 md:mt-0 md:flex-row items-start gap-x-2">
              {!isApprovalModalGroupView && hasApprovals && (
                <ApprovalActionButtons
                  pricingFlow={pricingFlow}
                  modelType={modelType}
                  approvalStatus={approvalStatuses[pricingFlow.id]}
                  user={user}
                  pageConfig={pageConfig}
                />
              )}
            </div>
            {onClick && (
              <div className="flex md:hidden w-full">
                <Button
                  color="white"
                  label="Details"
                  onClick={(e) => onClick(pricingFlow)}
                  className="flex-1"
                />
              </div>
            )}
          </Wrapper>
        );
      })}
    </>
  );
}

const hexToHSL = (hex: string) => {
  // Convert hex to RGB first
  let r = parseInt(hex.slice(1, 3), 16) / 255;
  let g = parseInt(hex.slice(3, 5), 16) / 255;
  let b = parseInt(hex.slice(5, 7), 16) / 255;

  // Find greatest and smallest channel values
  let cmin = Math.min(r, g, b);
  let cmax = Math.max(r, g, b);
  let delta = cmax - cmin;
  let h = 0;
  let s = 0;
  let l = 0;

  // Calculate hue
  if (delta === 0) h = 0;
  else if (cmax === r) h = ((g - b) / delta) % 6;
  else if (cmax === g) h = (b - r) / delta + 2;
  else h = (r - g) / delta + 4;

  h = Math.round(h * 60);
  if (h < 0) h += 360;

  // Calculate lightness
  l = (cmax + cmin) / 2;

  // Calculate saturation
  s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));

  return {
    h,
    s: Math.round(s * 100),
    l: Math.round(l * 100),
  };
};

export const getLightVariant = (hex: string) => {
  const { h, s, l } = hexToHSL(hex);
  // Create a very light, slightly saturated tint
  return `hsl(${h}, 85%, 96%)`;
};

function ApprovalFlow(props: {
  approvalStatus: ApprovalStatusForPricingFlow | undefined;
}) {
  const { approvalStatus } = props;
  const [expandedStep, setExpandedStep] = useState<string | null>(null);

  const getStepColor = (step: StepSnapshotWithStatus) => {
    if (step.status === 'APPROVED') return '#166534';
    return step.color;
  };
  const getIcon = (step: StepSnapshotWithStatus) => {
    if (step.status === 'APPROVED')
      return <CheckIcon className="w-3.5 h-3.5 mr-[2px]" />;
    if (step.status === 'PENDING')
      return <ClockIcon className="w-3.5 h-3.5 mr-[2px]" />;
    if (step.status === 'REQUIRES_PACKAGING')
      return <ExclamationTriangleIcon className="w-3.5 h-3.5 mr-[2px]" />;
  };
  const getStepStatus = (step: StepSnapshotWithStatus) => {
    if (step.status === 'APPROVED') return 'Approved';
    if (step.status === 'PENDING') return 'Required';
    if (step.status === 'REQUIRES_PACKAGING') return 'Requires packaging';
  };
  const getUserBadge = (status: UserApprovalStatus) => {
    switch (status) {
      case 'APPROVED':
        return <Badge color="green">Approved</Badge>;
      // case 'SMART_APPROVED':
      //   return <Badge color="green">Smart approved</Badge>;
      case 'REJECTED':
        return <Badge color="red">Rejected</Badge>;
      case 'PENDING':
        return <Badge color="yellow">Required</Badge>;
    }
  };

  if (isNil(approvalStatus)) return null;

  return (
    <div className="flex flex-col gap-4 w-full max-w-2xl">
      <div className="flex flex-row flex-wrap">
        {approvalStatus.approvalFlowWithStatuses.stepsWithStatus.map((step) => (
          <div key={step.id} className="flex flex-col">
            <Tooltip
              as="span"
              className=""
              location="TOP"
              text={getStepStatus(step)}
            >
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  setExpandedStep(expandedStep === step.id ? null : step.id);
                }}
              >
                <Badge
                  color="none"
                  className="hover:opacity-80 bg-none hover:bg-white border border-transparent hover:border-gray-200 content-center"
                  style={{
                    color: getStepColor(step),
                    opacity: isNil(expandedStep)
                      ? 1
                      : expandedStep === step.id
                        ? 1
                        : 0.6,
                  }}
                >
                  {getIcon(step)}
                  {step.name}
                </Badge>
              </button>
            </Tooltip>
          </div>
        ))}
      </div>
      {expandedStep && (
        <div className="flex flex-col gap-1">
          {approvalStatus.approvalFlowWithStatuses.stepsWithStatus.find(
            (s) => s.id === expandedStep,
          )?.status === 'REQUIRES_PACKAGING' && (
            // Information about the step that requires packaging
            <div className="flex items-center gap-3 p-2 bg-white rounded-lg border border-slate-200">
              <span className="text-sm font-medium">
                This step requires packaging. <br />
                You will need to add this quote to a group before it can be
                approved.
              </span>
            </div>
          )}
          {approvalStatus.approvalFlowWithStatuses.stepsWithStatus
            .find((s) => s.id === expandedStep)
            ?.usersWithStatus.map((user) => (
              <div
                key={user.name}
                className="flex items-center gap-8 p-2 bg-white rounded-lg border border-slate-200 flex-row justify-between"
              >
                <div className="flex flex-col gap-2 items-left">
                  <span className="text-sm font-medium">{user.name}</span>
                  {!isNil(user.requestedAt) && (
                    <>
                      <span className="text-xs text-slate-500">
                        Requested {dayjs(user.requestedAt).fromNow()}
                      </span>
                      <NudgeButton
                        userToNotify={user}
                        currentApprovalRequest={
                          approvalStatus.currentApprovalRequest
                        }
                      />
                    </>
                  )}
                </div>

                {getUserBadge(user.status)}
              </div>
            ))}
        </div>
      )}
    </div>
  );
}
function ApprovalStatus(props: {
  approvalStatus: ApprovalStatusForPricingFlow | undefined;
  displayApprovalFlowForDraft?: boolean;
}) {
  const { approvalStatus, displayApprovalFlowForDraft } = props;

  const [isApprovalFlowExpanded, setIsApprovalFlowExpanded] = useState(
    displayApprovalFlowForDraft,
  );
  const [approvalFlowDetails, setApprovalFlowDetails] = useState<{
    color: BadgeColor;
    text: string;
    displayDetails: boolean;
  } | null>(null);

  useEffect(() => {
    const statusDisplay = getStatusDisplay(approvalStatus);
    setIsApprovalFlowExpanded(
      statusDisplay.displayDetails || displayApprovalFlowForDraft,
    );
    setApprovalFlowDetails(statusDisplay);
  }, [approvalStatus]);

  if (isNil(approvalFlowDetails)) return null;

  function getStatusDisplay(
    approvalStatus: ApprovalStatusForPricingFlow | undefined,
  ): {
    color: BadgeColor;
    text: string;
    displayDetails: boolean;
  } {
    if (isNil(approvalStatus))
      return { color: 'gray', text: 'Draft', displayDetails: false };
    if (approvalStatus.currentApprovalRequest) {
      if (!isNil(approvalStatus.currentApprovalRequest.recalledAt)) {
        return { color: 'red', text: 'Recalled', displayDetails: true };
      }
      switch (approvalStatus.approvalFlowWithStatuses.status) {
        case 'APPROVED':
          return { color: 'green', text: 'Approved', displayDetails: true };
        case 'PENDING':
          return {
            color: 'orange',
            text: 'Pending approval',
            displayDetails: true,
          };
        case 'NO_APPROVAL_NEEDED':
          return {
            color: 'green',
            text: 'No approval needed',
            displayDetails: true,
          };
        case 'REQUIRES_PACKAGING':
          return {
            color: 'orange',
            text: 'Requires packaging',
            displayDetails: true,
          };
      }
    } else {
      switch (approvalStatus.approvalFlowWithStatuses.status) {
        case 'APPROVED':
          return {
            color: 'green',
            text: 'Smart approved',
            displayDetails: true,
          };
        case 'PENDING':
        case 'REQUIRES_PACKAGING':
          return { color: 'gray', text: 'Draft', displayDetails: false };
        case 'NO_APPROVAL_NEEDED':
          return {
            color: 'green',
            text: 'No approval needed',
            displayDetails: false,
          };
      }
    }
  }
  return (
    <div className="flex flex-col lg:flex-row gap-1">
      <div className="text-nowrap mr-1">
        <Badge color={approvalFlowDetails.color}>
          {approvalFlowDetails.text}
        </Badge>
      </div>
      {isApprovalFlowExpanded && (
        <span className="text-slate-200 hidden lg:block">|</span>
      )}
      {isApprovalFlowExpanded && (
        <ApprovalFlow approvalStatus={approvalStatus} />
      )}
    </div>
  );
}

function ApprovalActionButtons(props: {
  pricingFlow: PricingFlowOrSnapshotForNavigation;
  modelType: PricingFlowModelType;
  approvalStatus: ApprovalStatusForPricingFlow | undefined;
  user: User;
  pageConfig: OpportunityDetailPageConfig;
}) {
  const { pricingFlow, modelType, approvalStatus, user, pageConfig } = props;
  const { showModal, hideModal } = useModal();

  if (isNil(approvalStatus)) return null;
  const { currentApprovalRequest } = approvalStatus;

  const isApprover =
    approvalStatus?.approvalFlowWithStatuses.stepsWithStatus.some((s) =>
      s.usersWithStatus.some((u) => u.id === user.id),
    );

  const userApprovalStatus = isApprover
    ? approvalStatus.approvalFlowWithStatuses.stepsWithStatus
        .find((s) => s.usersWithStatus.some((u) => u.id === user.id))
        ?.usersWithStatus.find((u) => u.id === user.id)?.status
    : undefined;

  const isSubmitter = approvalStatus.submitter === user.id;

  const recallApprovalButton = isNil(currentApprovalRequest) ? null : (
    <Button
      key="recall-approval"
      color="white"
      onClick={async (e) => {
        e.stopPropagation();
        await api.put(
          `approvals/requests/${currentApprovalRequest.id}/actions/recall`,
          {},
        );
        window.location.reload();
      }}
      label="Recall approval"
    />
  );
  const recallRejectionButton = isNil(currentApprovalRequest) ? null : (
    <Button
      key="recall-rejection"
      color="white"
      onClick={async (e) => {
        e.stopPropagation();
        await api.put(
          `approvals/requests/${currentApprovalRequest.id}/actions/recall`,
          {},
        );
        window.location.reload();
      }}
      label="Recall rejection"
    />
  );

  const approvalButtons = isNil(currentApprovalRequest) ? null : (
    <div
      key="approval-buttons"
      className="flex items-center gap-x-2 w-full md:w-auto"
    >
      <Button
        color="red"
        onClick={async (e) => {
          e.stopPropagation();
          await api.post(
            `approvals/requests/${currentApprovalRequest.id}/actions`,
            { action: 'REJECT' },
          );
          window.location.reload();
        }}
        label="Reject"
        className="flex-1 md:flex-none"
      />
      <Button
        color="green"
        onClick={async (e) => {
          e.stopPropagation();
          await api.post(
            `approvals/requests/${currentApprovalRequest.id}/actions`,
            { action: 'APPROVE' },
          );
          window.location.reload();
        }}
        label="Approve"
        className="flex-1 md:flex-none"
      />
    </div>
  );

  const recallRequestButton =
    isNil(currentApprovalRequest) ||
    !user.permissions.includes('edit_pricing_flow') ? null : (
      <Button
        key="recall-request"
        color="white"
        onClick={async (e) => {
          e.stopPropagation();
          showModal({
            newStyle: true,
            title: 'Recall approval request',
            children: (
              <div className="flex-1 flex flex-col overflow-hidden">
                <span className="p-4 font-medium text-sm text-slate-600 mb-4">
                  Are you sure you want to recall this approval request?
                </span>
                <div className="mt-4 shrink-0 border-t border-100">
                  <div className="flex flex-row justify-between p-4 gap-4 sm:flex-row-reverse">
                    <Button
                      color="red"
                      onClick={async (e) => {
                        e.stopPropagation();
                        await api.put(
                          `approvals/requests/${currentApprovalRequest.id}/recall`,
                          {},
                        );
                        window.location.reload();
                      }}
                      label="Recall approval request"
                      className="flex-1"
                    />
                    <Button
                      color="white"
                      onClick={hideModal}
                      label="Cancel"
                      className="flex-1"
                    />
                  </div>
                </div>
              </div>
            ),
          });
        }}
        label="Recall request"
      />
    );

  if (modelType === 'pricingFlowSnapshot') {
    return null;
  }

  const actions = [];

  // Add actions for approvers
  if (isApprover && userApprovalStatus) {
    switch (userApprovalStatus) {
      case 'APPROVED':
        actions.push(recallApprovalButton);
        break;
      // case 'SMART_APPROVED':
      //   actions.push(
      //     <Button
      //       color="white"
      //       onClick={() => {
      //         navigate(`pricingflow/${pricingFlow.id}`, { relative: 'path' });
      //       }}
      //       label="Recall smart approval"
      //     />
      //   );
      //   break;
      case 'REJECTED':
        actions.push(recallRejectionButton);
        break;
      case 'PENDING':
        actions.push(approvalButtons);
        break;
      default:
        unreachable(userApprovalStatus);
    }
  }

  // Add actions for submitters
  if (isSubmitter) {
    actions.push(recallRequestButton);
    if (
      approvalStatus.approvalFlowWithStatuses.status === 'REQUIRES_PACKAGING'
    ) {
      actions.push(
        <SubmitForApprovalButton
          buttonProps={{ color: 'white', label: 'Add to group' }}
          pricingFlow={pricingFlow}
          user={user}
          approvalModalMode="GROUP_ONLY"
          pageConfig={pageConfig}
          key="add-to-group"
        />,
      );
    }
  }

  // If no active approval request, show "Submit for approval" button
  if (!approvalStatus.currentApprovalRequest) {
    actions.push(
      <SubmitForApprovalButton
        buttonProps={{ color: 'white', label: 'Submit for approval' }}
        pricingFlow={pricingFlow}
        user={user}
        approvalModalMode="GROUP_OR_INDIVIDUAL"
        pageConfig={pageConfig}
        key="submit-for-approval"
      />,
    );
  }

  return (
    <div className="flex w-full md:w-auto items-left md:items-center flex-col md:flex-row gap-y-2 md:gap-y-0 md:gap-x-2">
      {actions.filter((a) => !isNil(a))}
    </div>
  );
}
