import { datadogRum } from '@datadog/browser-rum';
import { Dialog, Transition } from '@headlessui/react';
import { PlusIcon } from '@heroicons/react/24/outline';
import { produce, WritableDraft } from 'immer';
import { isNil } from 'lodash';
import { Fragment, useState } from 'react';
import Badge from 'src/components/Badge';
import Button from 'src/components/Button';
import EditableTitle from 'src/components/EditableTitle';
import { FormattedNumberField } from 'src/components/Fields';
import Tooltip from 'src/components/Tooltip';
import { classNames } from 'src/dashboard/App';
import { typedEntries, unreachable } from 'src/typeUtils';
import { ParentTagBadge } from '../Activity/ActivitySection';
import { formatCurrencyValue } from '../Alpaca/alpaca_utils';
import { QuotePriceEditable } from '../Alpaca/Components/AlpacaQuotePriceEditable';
import { QuoteTableBodyEmptyState } from '../Alpaca/Components/AlpacaQuoteTableEmptyState';
import { usePricingFlowContext } from '../PricingFlow';
import { getLightVariant } from '../PricingFlowList';
import { VolumeEditable } from '../QuoteTables/VolumeEditable';
import TrashButton from '../TrashButton';
import {
  Count,
  CurrencyValue,
  CurrencyValueFlat,
  CurrencyValuePercent,
  CurrencyValueTiered,
  CurrencyValueType,
  ZERO_FLAT,
} from '../types_common/price';
import HamsterProductSelectionModal from './HamsterProductSelectionModal';
import {
  ApprovalsDisplay,
  HamsterCV,
  HamsterPricingFlow,
  HamsterPricingInformation,
  HamsterProduct,
  HamsterQuotePrice,
  StepId,
  StepSnapshot,
} from './hamster_types';
import {
  estimatedMonthlyRevenue,
  getBlendedPriceForVolume,
  getListPriceForVolume,
} from './hamster_utils';

interface ListPriceBadgeProps {
  product: HamsterProduct;
}
function ListPriceBadge(props: ListPriceBadgeProps) {
  const { product } = props;
  const { pricingFlow, updateFlow, editMode } =
    usePricingFlowContext<HamsterPricingFlow>();
  const listPrice =
    pricingFlow.pricingSheetData.productInfo[product.id]?.currentPricingCurve
      .pricingInformation.listPrice;
  const onClick = () => {
    updateFlow(
      produce(pricingFlow, (draft) => {
        const productToUpdate = draft.products.find((p) => p.id === product.id);
        if (productToUpdate) {
          productToUpdate.quotePrice = listPrice ?? null;
        }
      }),
      false,
    );
  };
  switch (listPrice?.type) {
    case 'tiered': {
      const blendedListPrice = getBlendedPriceForVolume(
        product.volume,
        listPrice.tiers,
      );
      const tooltipText = listPrice.tiers
        .map(
          (t) =>
            `≥${formatCurrencyValue(t.minimum)}:  ${formatCurrencyValue(t.currencyValue, 0)}`,
        )
        .join('\n');
      return (
        <div className="flex flex-col justify-start items-start mt-[-12px]">
          <div
            className="w-full text-gray-400"
            style={{ fontSize: '10px', lineHeight: '12px' }}
          >
            Tiered
          </div>

          <Tooltip
            as="button"
            location="LEFT"
            text={<div className="text-left">{tooltipText}</div>}
            className=""
            disabled={!editMode || isNil(blendedListPrice)}
            onClick={onClick}
          >
            <Badge
              color="purple"
              className={!isNil(blendedListPrice) ? 'hover:bg-purple-200' : ''}
            >
              {formatCurrencyValue(blendedListPrice, 2)}
            </Badge>
          </Tooltip>
        </div>
      );
    }
    default: {
      return (
        <div className="flex flex-col justify-start items-start mt-[-12px]">
          <div
            className="w-full text-gray-400"
            style={{ fontSize: '10px', lineHeight: '12px' }}
          >
            <div className="h-[12px]" />
          </div>

          <button
            className=""
            title="Set quote price to rack rate"
            disabled={!editMode || isNil(listPrice)}
            onClick={onClick}
          >
            <Badge
              color="purple"
              className={!isNil(listPrice) ? 'hover:bg-purple-200' : ''}
            >
              {formatCurrencyValue(listPrice as unknown as CurrencyValue, 2)}
            </Badge>
          </button>
        </div>
      );
    }
  }
}

interface ProductPricingSummary {
  blendedListPrice: CurrencyValueFlat | null;
  blendedQuotePrice: CurrencyValueFlat;
  discount: CurrencyValuePercent | null;
}
export function computeProductPricingSummary(
  product: HamsterProduct,
  pricingFlow: HamsterPricingFlow,
  monthIdx: number | null,
): ProductPricingSummary {
  const pricingInfo =
    pricingFlow.pricingSheetData.productInfo[product.id]?.currentPricingCurve
      .pricingInformation;
  const volume =
    isNil(monthIdx) || isNil(product.rampedVolumeIncremental[monthIdx])
      ? product.volume
      : product.volume + product.rampedVolumeIncremental[monthIdx].value;
  const blendedListPrice =
    pricingInfo?.listPrice?.type === 'tiered'
      ? getBlendedPriceForVolume(product.volume, pricingInfo?.listPrice?.tiers)
      : (pricingInfo?.listPrice ?? null);
  const quotePriceVal = getQuotePrice(product, volume);
  const blendedQuotePrice: CurrencyValueFlat = quotePriceVal
    ? {
        type: CurrencyValueType.FLAT,
        value: quotePriceVal,
        currency: 'USD',
      }
    : (blendedListPrice ?? ZERO_FLAT('USD'));
  const discount: CurrencyValuePercent | null = isNil(blendedListPrice)
    ? null
    : {
        type: CurrencyValueType.PERCENT,
        value:
          (100 * (blendedListPrice.value - blendedQuotePrice.value)) /
          blendedListPrice.value,
      };
  return { blendedListPrice, blendedQuotePrice, discount };
}

export function formatNumberAsUSDCV(n: number, numDecimals: number) {
  return formatCurrencyValue(
    {
      type: CurrencyValueType.FLAT,
      value: n,
      currency: 'USD',
    },
    numDecimals,
  );
}

function _getStepOrderForPricingCurveTier(
  stepId: StepId,
  stepSnapshots: StepSnapshot[],
): number {
  const stepOrder = stepSnapshots.find(
    (snapshot) => snapshot.stepId === stepId,
  )?.order;
  if (isNil(stepOrder)) {
    datadogRum.addError(`Did not find step snapshot name matching ${stepId}`);
    return 0;
  }
  return stepOrder;
}

function _getTopLevelApprovalLevelForProduct(
  product: HamsterProduct,
  pricingInfo: HamsterPricingInformation,
  stepSnapshots: StepSnapshot[],
): StepSnapshot | null {
  // null return means no approval needed
  const quotePrice = product.quotePrice;
  switch (quotePrice?.type) {
    case CurrencyValueType.FLAT:
      return _getApprovalLevelForPrice({
        price: quotePrice,
        volume: product.volume,
        pricingInfo,
        stepSnapshots,
      });
    case 'tiered':
      return _getApprovalLevelForPrice({
        price: getBlendedPriceForVolume(product.volume, quotePrice.tiers),
        volume: product.volume,
        pricingInfo,
        stepSnapshots,
      });
    case undefined:
      const price =
        pricingInfo.listPrice?.type === 'tiered'
          ? getBlendedPriceForVolume(
              product.volume,
              pricingInfo.listPrice?.tiers,
            )
          : pricingInfo.listPrice;
      return _getApprovalLevelForPrice({
        price,
        volume: product.volume,
        pricingInfo,
        stepSnapshots,
      });
    default:
      unreachable(quotePrice);
  }
}

function _getApprovalLevelForPrice({
  price,
  volume,
  pricingInfo,
  stepSnapshots,
}: {
  price: HamsterCV | null;
  volume: number;
  pricingInfo: HamsterPricingInformation;
  stepSnapshots: StepSnapshot[];
}) {
  let approvalStepOrder: number | null = null;
  const requiresApproval = (
    approvalTiers:
      | CurrencyValueFlat
      | CurrencyValueTiered<CurrencyValueFlat, Count>
      | true
      | null
      | undefined,
  ) => {
    if (isNil(approvalTiers)) {
      return false;
    }
    if (approvalTiers === true) {
      return true;
    }
    const quotePriceValue =
      price?.value ?? getListPriceForVolume(volume, pricingInfo)?.value ?? 0;
    if (approvalTiers.type === CurrencyValueType.FLAT) {
      return approvalTiers.value > quotePriceValue;
    }
    const approvalAmount = getBlendedPriceForVolume(
      volume,
      approvalTiers.tiers,
    );
    if (isNil(approvalAmount)) {
      return false;
    }
    return approvalAmount.value > quotePriceValue;
  };
  for (const [stepId, approvalTiers] of typedEntries(
    pricingInfo.approvalThresholds,
  )) {
    if (requiresApproval(approvalTiers)) {
      const stepOrder = _getStepOrderForPricingCurveTier(stepId, stepSnapshots);
      approvalStepOrder = isNil(approvalStepOrder)
        ? stepOrder
        : Math.max(approvalStepOrder, stepOrder);
    }
  }
  return isNil(approvalStepOrder)
    ? null
    : (stepSnapshots.find(
        (stepSnapshot) => stepSnapshot.order === approvalStepOrder,
      ) ?? null);
}

interface ApprovalBadgeProps {
  // null means no approval needed
  stepSnapshot: StepSnapshot | null;
}
export function ApprovalLevelBadge(props: ApprovalBadgeProps) {
  const { stepSnapshot } = props;
  const color = stepSnapshot?.color ?? '#166534';
  const name = stepSnapshot?.name ?? 'None';
  return (
    <Badge
      color="gray"
      style={{
        color,
        background: getLightVariant(color),
      }}
    >
      {name}
    </Badge>
  );
}

// #HamsterGetQuotePrice
function getQuotePrice(product: HamsterProduct, volumeForMonth: number) {
  const quotePrice = product.quotePrice;
  if (isNil(quotePrice)) {
    return null;
  }
  switch (quotePrice.type) {
    case CurrencyValueType.FLAT:
      return quotePrice.value;
    case 'tiered':
      return getBlendedPriceForVolume(volumeForMonth, quotePrice.tiers)?.value;
    default:
      unreachable(quotePrice);
  }
}
interface QuoteTableRowProps {
  product: HamsterProduct;
  approvalsNeeded: ApprovalsDisplay;
}
function QuoteTableRow(props: QuoteTableRowProps) {
  const { product, approvalsNeeded } = props;
  const { pricingFlow, updateFlow, editMode } =
    usePricingFlowContext<HamsterPricingFlow>();
  const productInfo = pricingFlow.pricingSheetData.productInfo[product.id];
  if (isNil(productInfo)) {
    return null;
  }
  const pricingInfo = productInfo.currentPricingCurve.pricingInformation;
  const { blendedListPrice, discount } = computeProductPricingSummary(
    product,
    pricingFlow,
    null,
  );
  const estMonthlyRevenue = estimatedMonthlyRevenue(
    product,
    productInfo,
    pricingFlow.additionalData.subscriptionTerms,
    'prorated',
  );
  const handleDelete = () => {
    const newProducts = pricingFlow.products.filter((p) => p.id !== product.id);
    updateFlow({ ...pricingFlow, products: newProducts }, false);
  };
  return (
    <>
      {/* Main product row */}
      <tr key={product.id}>
        {/* Product */}
        <td className="flex min-w-[172px] items-center gap-4 border-b border-gray-200 p-2 md:px-6 md:py-4 h-full">
          <div className="flex flex-row items-center">
            {productInfo.isFreeformProduct && (
              <ParentTagBadge parentTag="Custom" />
            )}
            {productInfo.isFreeformProduct && editMode ? (
              <EditableTitle
                title={product.customName ?? productInfo.name}
                updateTitle={(newTitle) => {
                  updateFlow(
                    produce(pricingFlow, (draftFlow) => {
                      const productToRename = draftFlow.products.find(
                        (p) => p.id === product.id,
                      );
                      if (productToRename)
                        productToRename.customName = newTitle;
                    }),
                    false,
                  );
                }}
                disabled={!editMode}
                textSize="text-xs md:text-sm"
                fontWeight="font-medium"
                className="max-w-[240px] md:max-w-[460px]"
              />
            ) : (
              <span className="text-xs md:text-sm font-medium text-gray-900">
                {product.customName ?? productInfo.name}
              </span>
            )}
          </div>
        </td>
        {/* Quantity */}
        <td className="whitespace-nowrap border-b border-gray-200 text-sm text-gray-900">
          {!isNil(productInfo.fixedVolume) ? (
            <div className="w-28 h-full pl-1">
              <FormattedNumberField
                type="text"
                value={productInfo.fixedVolume}
                required={true}
                numberDecimals={0}
                className="border-none bg-transparent text-xs md:text-sm text-gray-900 outline-none focus:border-none focus:ring-0 focus:ring-transparent h-full w-28"
                updateValue={() => {}}
                disabled={true}
              />
            </div>
          ) : (
            <VolumeEditable
              volume={product.volume}
              updateValue={(value: number) => {
                updateFlow(
                  produce(pricingFlow, (draft) => {
                    const productToUpdate = draft.products.find(
                      (p) => p.id === product.id,
                    );
                    if (productToUpdate) {
                      productToUpdate.volume = value;
                    }
                  }),
                  false,
                );
              }}
              rampedVolumeIncremental={product.rampedVolumeIncremental}
              months={pricingFlow.additionalData.subscriptionTerms}
              setRampedVolumeIncremental={(
                rampedVolumeIncremental: (WritableDraft<Count> | null)[],
              ) => {
                updateFlow(
                  produce(pricingFlow, (draft) => {
                    const productToUpdate = draft.products.find(
                      (p) => p.id === product.id,
                    );
                    if (productToUpdate) {
                      productToUpdate.rampedVolumeIncremental =
                        rampedVolumeIncremental;
                    }
                  }),
                  false,
                );
              }}
              disabled={!editMode}
              productName={productInfo.name}
              calculateEstimatedMonthlyRevenue={(params: {
                baseVolume: number;
                monthIdx: number;
                rampedVolumeIncremental: (Count | null)[];
              }) => {
                return estimatedMonthlyRevenue(
                  {
                    ...product,
                    volume: params.baseVolume,
                    rampedVolumeIncremental: params.rampedVolumeIncremental,
                  },
                  productInfo,
                  pricingFlow.additionalData.subscriptionTerms,
                  params.monthIdx,
                );
              }}
            />
          )}
        </td>
        {/* List price */}
        <td className="whitespace-nowrap border-b border-gray-200 p-2 md:px-6 md:py-4 text-xs font-medium text-purple-700">
          <ListPriceBadge product={product} />
        </td>
        {/* Proposed price */}
        <td
          className="overflow-show h-full w-full p-0 align-top border-b border-gray-200 min-w-[120px]"
          onClick={(e: React.MouseEvent<HTMLTableDataCellElement>) => {}}
        >
          <QuotePriceEditable
            quotePrice={
              product.quotePrice ?? blendedListPrice ?? ZERO_FLAT('USD')
            }
            productVolume={product.volume}
            updateQuotePrice={(newQuotePrice) => {
              updateFlow(
                produce(pricingFlow, (draft) => {
                  const productToUpdate = draft.products.find(
                    (p) => p.id === product.id,
                  );
                  if (productToUpdate) {
                    productToUpdate.quotePrice =
                      (newQuotePrice as HamsterQuotePrice) ?? ZERO_FLAT('USD');
                  }
                }),
                false,
              );
            }}
            validPriceTypes={[CurrencyValueType.FLAT]}
            validTierMinimumTypes={['count']}
            quoteCurrency="USD"
            stickerPrice={null}
            cost={null}
            productName={productInfo.name}
            tierable={isNil(productInfo.fixedVolume)}
            tierConfig={{
              showBlendedPrice: true,
              showStickerPrice: false,
              showCost: false,
              showApprovalLevel: false,
              quotePriceColumnName: 'Proposed Price',
              countMinimumHeaderOverride: 'Quantity ≥',
              countMinimumSuffixOverride: '',
              additionalPrices: [],
              getSummaryData: (liveQuotePrice) => {
                const productWithLiveQuotePrice = {
                  ...product,
                  quotePrice: liveQuotePrice as HamsterQuotePrice,
                };
                return [
                  {
                    header: 'Discount',
                    value: formatCurrencyValue(
                      computeProductPricingSummary(
                        productWithLiveQuotePrice,
                        pricingFlow,
                        null,
                      ).discount,
                    ),
                  },
                  {
                    header: 'Approval level',
                    value: (
                      <ApprovalLevelBadge
                        stepSnapshot={_getTopLevelApprovalLevelForProduct(
                          productWithLiveQuotePrice,
                          pricingInfo,
                          approvalsNeeded.stepSnapshots,
                        )}
                      />
                    ),
                  },
                ];
              },
            }}
          />
        </td>
        {/* Discount */}
        <td className="whitespace-nowrap border-b border-gray-200 p-2 md:px-6 md:py-4 text-xs sm:text-sm font-medium text-gray-500">
          {formatCurrencyValue(discount, 1)}
        </td>
        {/* Approval level */}
        <td className="whitespace-nowrap border-b border-gray-200 p-2 md:px-6 md:py-4 text-xs font-medium text-green-700">
          <ApprovalLevelBadge
            stepSnapshot={
              approvalsNeeded.productsNeeded[product.id]?.greatestStep
            }
          />
        </td>
        {/* Est. Monthly revenue */}
        <td className="whitespace-nowrap border-b border-gray-200 p-2 md:px-6 md:py-4 text-xs sm:text-sm font-medium text-gray-500">
          {formatNumberAsUSDCV(estMonthlyRevenue, 2)}
        </td>
        {/* Trash icon */}
        <td className="border-b border-gray-200 p-2">
          <TrashButton editMode={editMode} handleDelete={handleDelete} />
        </td>
      </tr>
    </>
  );
}

interface HamsterQuoteTableProps {
  approvalsNeeded: ApprovalsDisplay;
}
export default function HamsterQuoteTable(props: HamsterQuoteTableProps) {
  const { pricingFlow, editMode } = usePricingFlowContext<HamsterPricingFlow>();
  const [showProductSelectionModal, setShowProductSelectionModal] =
    useState(false);
  const { approvalsNeeded } = props;
  return (
    <div className="my-0 md:my-2 w-full self-stretch">
      {/* Outer container with border and rounded corners */}
      <div className="rounded-none md:rounded-xl border border-gray-200 bg-white overflow-hidden">
        {/* Scrollable container */}
        <div className="overflow-auto">
          <table className="h-full min-w-full border-separate border-spacing-0">
            <thead>
              <tr>
                <th
                  scope="col"
                  className="sticky top-0 z-10 align-bottom w-full border-b bg-gray-50 p-2 md:px-6 md:py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter first:rounded-tl-xl"
                >
                  Products
                </th>
                <th
                  scope="col"
                  className="sticky top-0 z-10 align-bottom border-b bg-gray-50 p-2 md:pl-4 md:pr-6 md:py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter whitespace-nowrap"
                >
                  Quantity
                </th>
                <th
                  scope="col"
                  className="sticky top-0 z-10 align-bottom border-b bg-gray-50 p-2 md:px-6 md:py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter whitespace-nowrap"
                >
                  List Price
                </th>
                <th
                  scope="col"
                  className="has-tooltip sticky top-0 z-10 align-bottom border-b bg-gray-50 p-2 md:pl-4 md:pr-6 md:py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter whitespace-nowrap"
                >
                  Proposed Price
                </th>
                <th
                  scope="col"
                  className="sticky top-0 z-10 align-bottom border-b bg-gray-50 p-2 md:px-6 md:py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter whitespace-nowrap"
                >
                  Discount
                </th>
                <th
                  scope="col"
                  className="sticky top-0 z-10 align-bottom border-b bg-gray-50 p-2 md:px-6 md:py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter whitespace-nowrap"
                >
                  Approval Level
                </th>
                <th
                  scope="col"
                  className="sticky top-0 z-10 align-bottom border-b bg-gray-50 p-2 md:px-6 md:py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter whitespace-nowrap"
                >
                  Est. Monthly Revenue
                </th>
                {/* trash icon */}
                <th className="sticky top-0 z-10 align-bottom border-b bg-gray-50 p-2 md:px-6 md:py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter whitespace-nowrap last:rounded-tr-xl"></th>
              </tr>
            </thead>
            <tbody>
              {pricingFlow.products.length > 0 ? (
                pricingFlow.products
                  .sort((a, b) => {
                    return (
                      (pricingFlow.pricingSheetData.productInfo[a.id]
                        ?.displayOrder ?? 0) -
                      (pricingFlow.pricingSheetData.productInfo[b.id]
                        ?.displayOrder ?? 0)
                    );
                  })
                  .map((product) => {
                    return (
                      <QuoteTableRow
                        key={product.id}
                        product={product}
                        approvalsNeeded={approvalsNeeded}
                      />
                    );
                  })
              ) : (
                <QuoteTableBodyEmptyState colSpan={6} />
              )}
            </tbody>
            <tfoot>
              <tr className={classNames(!editMode && 'hidden md:table-row')}>
                <th
                  scope="col"
                  colSpan={5}
                  className="bg-slate-50 p-2 md:px-6 md:py-3.5 text-left text-sm
                font-semibold text-gray-700 backdrop-blur backdrop-filter
                sm:table-cell xl:whitespace-nowrap rounded-bl-xl rounded-br-xl"
                >
                  <Button
                    color="white"
                    onClick={() => {
                      setShowProductSelectionModal(true);
                    }}
                    disabled={!editMode}
                  >
                    <PlusIcon
                      className="-ml-0.5 mr-1 h-4 w-4"
                      aria-hidden="true"
                    />
                    Add / Edit Products
                  </Button>
                </th>
                <th
                  scope="col"
                  colSpan={7}
                  className="bg-slate-50 p-2 md:px-6 md:py-3.5 text-left text-sm font-semibold text-gray-700 backdrop-blur backdrop-filter sm:table-cell xl:whitespace-nowrap rounded-bl-xl rounded-br-xl"
                ></th>
              </tr>
            </tfoot>
          </table>
        </div>
        <Transition appear show={showProductSelectionModal} as={Fragment}>
          <Dialog
            as="div"
            className="absolute z-50 items-center justify-center overflow-y-auto min-w-full"
            onClose={() => {
              setShowProductSelectionModal(false);
            }}
          >
            {/* This transitions the background to a dark shade */}
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black/25" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto lg:px-20">
              <div className="min-h-full flex items-center justify-center px-8 py-4 text-center">
                <Transition.Child
                  className="w-full"
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <HamsterProductSelectionModal
                    closeModal={() => {
                      setShowProductSelectionModal(false);
                    }}
                  />
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
      </div>
    </div>
  );
}
