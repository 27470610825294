import { CheckIcon } from '@heroicons/react/24/outline';
import { EditorStatus } from '@milkdown/core';
import { Crepe } from '@milkdown/crepe';
import '@milkdown/crepe/theme/common/style.css';
import '@milkdown/crepe/theme/frame.css';
import { listener, listenerCtx } from '@milkdown/kit/plugin/listener';
import { replaceAll } from '@milkdown/kit/utils';
import _, { isNil } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import 'src/components/CrepeEditor.css';
import { classNames } from 'src/dashboard/App';
import { InlineSpinner } from './Loading';

type SaveStatus = 'idle' | 'saving' | 'saved';
interface SaveStatusIndicatorProps {
  status: SaveStatus;
  className: string;
}

const SaveStatusIndicator: React.FC<SaveStatusIndicatorProps> = ({
  status,
  className,
}) => {
  return (
    <div
      className={classNames(
        `transition-opacity duration-200 h-8 flex items-center gap-x-1 text-xs text-gray-700 mr-2`,
        status === 'idle' ? 'opacity-0' : 'opacity-100',
        className,
      )}
    >
      {status === 'saving' && (
        <>
          <InlineSpinner height={16} width={16} />
          <span>Saving...</span>
        </>
      )}
      {status === 'saved' && (
        <>
          <CheckIcon className="h-4 w-4 text-green-500" />
          <span>Saved</span>
        </>
      )}
    </div>
  );
};

export function CrepeDisplayReadonly(props: {
  value: string | undefined | null;
  placeholder?: string;
  className?: string;
}) {
  const { value, placeholder, className } = props;
  return (
    <CrepeEditorWithAutosave
      initialValue={value}
      placeholder={placeholder}
      className={className}
      editableMode="no-edit"
    />
  );
}

type CrepeEditorWithAutosaveProps = {
  // The initial value to show in the crepe. Note that this component is
  // not controlled - the crepe has its own internal state for what the
  // markdown is
  initialValue: string | null | undefined;
  // If the given markdown is null, display defaultValueForDisplay instead, at
  // least until the initial edit. This is a separate prop because if the crepe
  // contents are this default value, it can be swapped out for
  // templateForEditing on initial focus.
  placeholder: string | undefined;
  renderAdditionalActions?: (crepe: Crepe) => React.ReactNode;
  className?: string;
} & (
  | {
      editableMode: 'edit-only' | 'default';
      onBlur: () => void;
      // onMarkdownUpdated is debounced, setMarkdown is not. Use:
      // - setMarkdown to set a parent's state
      // - onMarkdownUpdated for expensive ops, e.g. saving to the db
      // if onMarkdownUpdated is null, we will not show the saving indicator,
      // since the whole point of that indicator is to show the progress of
      // this callback
      onMarkdownUpdated: ((newMarkdown: string | null) => Promise<void>) | null;
      setMarkdown?: (newMarkdown: string | null) => void;
      // On first edit, show this template instead of the default value for
      // display
      templateForEditing: string | null;
    }
  | {
      // If readonly, you don't need any props about editing
      editableMode: 'no-edit';
      onBlur?: never;
      onMarkdownUpdated?: never;
      setMarkdown?: never;
      templateForEditing?: never;
    }
);
// Creates a crepe editor that automatically saves its text as it's updated via
// onMarkdownUpdated. If you need to modify the crepe's markdown state
// imperatively (e.g. via buttons), you can try to render such buttons using
// the renderAdditionalActions prop. However, for buttons that are sufficiently
// unrelated in the component tree, you may have to implement similar logic in
// the caller (e.g. see AddComment)
export function CrepeEditorWithAutosave(props: CrepeEditorWithAutosaveProps) {
  const {
    initialValue,
    placeholder,
    templateForEditing,
    className,
    editableMode,
    onMarkdownUpdated,
    setMarkdown,
    onBlur,
    renderAdditionalActions,
  } = props;
  const [saveStatus, setSaveStatus] = useState<SaveStatus>('idle');
  const [crepe, setCrepe] = useState<Crepe | null>(null);
  const saveVersionRef = useRef(0);
  const instanceId = useRef(crypto.randomUUID());
  const debouncedOnMarkdownUpdated = useCallback(
    _.debounce(async (newMarkdown: string | null) => {
      if (isNil(onMarkdownUpdated)) {
        return;
      }
      setSaveStatus('saving');
      const currentVersion = ++saveVersionRef.current;
      await onMarkdownUpdated(newMarkdown);
      if (currentVersion === saveVersionRef.current) {
        setSaveStatus('saved');
      }
    }, 500),
    [],
  );

  useEffect(() => {
    // create new crepe
    const newCrepe = new Crepe({
      root: `#crepeeditor--${instanceId.current}`,
      defaultValue: initialValue ?? undefined,
      featureConfigs: {
        [Crepe.Feature.Placeholder]: {
          text: placeholder,
          mode: 'doc',
        },
      },
    });

    if (editableMode === 'no-edit') {
      newCrepe.setReadonly(true);
    } else {
      newCrepe.editor
        .config((ctx) => {
          const listener = ctx.get(listenerCtx);
          // create listener callbacks
          listener
            .markdownUpdated(async (ctx, newMarkdownRaw, prevMarkdown) => {
              const newMarkdown = newMarkdownRaw.trim();
              setSaveStatus('idle');
              if (
                newMarkdown !== prevMarkdown &&
                newMarkdown !== placeholder &&
                newMarkdown !== templateForEditing
              ) {
                setMarkdown && setMarkdown(newMarkdown);
                if (newMarkdown === '') {
                  await debouncedOnMarkdownUpdated(null);
                } else {
                  await debouncedOnMarkdownUpdated(newMarkdown);
                }
              }
            })
            .focus(async (ctx) => {
              if (
                isNil(templateForEditing) ||
                newCrepe.editor.status !== EditorStatus.Created
              ) {
                return;
              }
              const markdown = newCrepe.getMarkdown().trim();
              if (markdown === '' || markdown === placeholder) {
                newCrepe.editor.action(replaceAll(templateForEditing));
              }
            })
            .blur((ctx) => {
              onBlur();
            });
        })
        .use(listener);
    }

    newCrepe.create().then(() => {
      console.log('Crepe editor created', instanceId.current);
      setCrepe(newCrepe);
    });

    return () => {
      // Destroy the editor when unmounting component
      newCrepe.destroy();
      console.log('Crepe editor destroyed', instanceId.current);
    };
  }, [
    debouncedOnMarkdownUpdated,
    placeholder,
    editableMode,
    templateForEditing,
    // Note: initialValue and setMarkdown are intentionally omitted from the
    // dependencies here
  ]);

  return (
    <>
      <div className="flex flex-col gap-y-2 relative h-full">
        <div
          id={`crepeeditor--${instanceId.current}`}
          className={classNames(
            editableMode !== 'no-edit' &&
              'bg-white p-4 pb-6 rounded-lg focus-within:ring-2 ring-fuchsia-900',
            editableMode === 'default' &&
              'hover:bg-gray-100 focus-within:!bg-white',
            editableMode === 'edit-only' && 'border border-gray-300',
            className,
          )}
        />
        {editableMode !== 'no-edit' && !isNil(onMarkdownUpdated) && (
          <SaveStatusIndicator
            status={saveStatus}
            className="absolute bottom-2 right-4"
          />
        )}
      </div>
      {renderAdditionalActions && !isNil(crepe)
        ? renderAdditionalActions(crepe)
        : null}
    </>
  );
}
