import { Transition } from '@headlessui/react';
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import { produce } from 'immer';
import { useState } from 'react';
import { FormattedNumberField } from 'src/components/Fields';
import { useToast } from 'src/components/Toast';
import { ApprovalBadge } from '../Approvals/ApprovalModal';
import { usePricingFlowContext } from '../PricingFlow';
import { ApprovalsDisplay, HamsterPricingFlow } from './hamster_types';

function PriceProtectionAtRenewalCheckbox(props: {
  approvalsNeeded: ApprovalsDisplay;
}) {
  const { pricingFlow, updateFlow, editMode } =
    usePricingFlowContext<HamsterPricingFlow>();

  const maybeApproval = props.approvalsNeeded.fieldsNeeded.priceProtection;
  const step = maybeApproval?.greatestStep.name;
  return (
    <label className="flex gap-x-3 p-4 mt-2 border border-gray-300 rounded-xl items-start">
      <input
        type="checkbox"
        checked={pricingFlow.additionalData.priceProtection}
        className="m-1 h-4 w-4 border-gray-300 text-fuchsia-900 focus:ring-fuchsia-900 focus:border-fuchsia-900 rounded-sm"
        onChange={() => {
          updateFlow(
            produce(pricingFlow, (draft) => {
              draft.additionalData.priceProtection =
                !draft.additionalData.priceProtection;
            }),
            false,
          );
        }}
        disabled={!editMode}
      />
      <div className="flex flex-col gap-y-1 font-medium text-sm">
        {/* <span>Price Protection at Renewal</span> */}
        <label className="block text-sm font-medium text-gray-900 mt-0.5">
          Price Protection at Renewal
          {maybeApproval && (
            <span className="ml-2">
              <ApprovalBadge level={step} />
            </span>
          )}
        </label>
        <Transition
          show={pricingFlow.additionalData.priceProtection}
          as={'div'}
          className="flex flex-col items-start gap-y-2"
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <span className="text-slate-500 font-medium text-sm">
            Price Protection Cap
          </span>
          <FormattedNumberField
            type="text"
            value={
              pricingFlow.additionalData.priceProtection
                ? pricingFlow.additionalData.priceProtectionCap
                : 0
            }
            required={true}
            numberDecimals={0}
            className="cursor-pointer outline-1 outline-gray-300 rounded-md bg-transparent text-gray-900 outline-none border-none focus:ring-0 focus:ring-transparent w-20 focus-within:outline-fuchsia-900"
            data-volume-editable
            suffix="%"
            disabled={!pricingFlow.additionalData.priceProtection || !editMode}
            updateValue={(value: number) => {
              updateFlow(
                produce(pricingFlow, (draft) => {
                  if (draft.additionalData.priceProtection) {
                    draft.additionalData.priceProtectionCap = value;
                  }
                }),
                false,
              );
            }}
          />
        </Transition>
      </div>
    </label>
  );
}
function BillingFrequencySelector(props: {
  approvalsNeeded: ApprovalsDisplay;
}) {
  const { pricingFlow, updateFlow, editMode } =
    usePricingFlowContext<HamsterPricingFlow>();

  const maybeApproval = props.approvalsNeeded.fieldsNeeded.billingFrequency;
  const step = maybeApproval?.greatestStep.name;
  return (
    <div className="flex flex-col">
      <label className="text-sm font-medium text-gray-900 mb-2 min-h-6 flex items-center">
        Billing Frequency
        {maybeApproval && (
          <span className="ml-2">
            <ApprovalBadge level={step} />
          </span>
        )}
      </label>
      <select
        className="text-md rounded-md outline-1 outline-gray-300 bg-transparent p-2 text-gray-900 shadow-sm outline-none border-none focus-within:outline focus-within:outline-2 focus-within:outline-fuchsia-900 focus:border-none focus:ring-0 focus:ring-transparent w-full"
        value={pricingFlow.additionalData.billingFrequency}
        onChange={(e) => {
          updateFlow(
            produce(pricingFlow, (draft) => {
              draft.additionalData.billingFrequency = e.target.value as any;
            }),
            false,
          );
        }}
        disabled={!editMode}
      >
        <option value="annual_upfront">Annual Upfront</option>
        <option value="semi_annual">Semi-Annual</option>
        <option value="quarterly">Quarterly</option>
        <option value="other">Other</option>
      </select>
      {pricingFlow.additionalData.billingFrequency === 'other' && (
        <input
          type="text"
          className="mt-4 text-md rounded-md outline-1 outline-gray-300 bg-transparent p-2 text-gray-900 shadow-sm outline-none border-none focus-within:outline focus-within:outline-2 focus-within:outline-fuchsia-900 focus:border-none focus:ring-0 focus:ring-transparent w-full placeholder:text-gray-400"
          placeholder="Custom billing frequency..."
          value={pricingFlow.additionalData.billingFrequency_Other}
          onChange={(e) => {
            updateFlow(
              produce(pricingFlow, (draft) => {
                draft.additionalData.billingFrequency_Other = e.target
                  .value as any;
              }),
              false,
            );
          }}
          disabled={!editMode}
        ></input>
      )}
    </div>
  );
}
function PaymentTermsSelector(props: { approvalsNeeded: ApprovalsDisplay }) {
  const { pricingFlow, updateFlow, editMode } =
    usePricingFlowContext<HamsterPricingFlow>();

  const maybeApproval = props.approvalsNeeded.fieldsNeeded.paymentTerms;
  const step = maybeApproval?.greatestStep.name;
  return (
    <div className="flex flex-col">
      <label className="text-sm font-medium text-gray-900 mb-2 min-h-6 flex items-center">
        Payment Terms
        {maybeApproval && (
          <span className="ml-2 ">
            <ApprovalBadge level={step} />
          </span>
        )}
      </label>
      <select
        className="text-md rounded-md outline-1 outline-gray-300 bg-transparent p-2 text-gray-900 shadow-sm outline-none border-none focus-within:outline focus-within:outline-2 focus-within:outline-fuchsia-900 focus:border-none focus:ring-0 focus:ring-transparent w-full"
        value={pricingFlow.additionalData.paymentTerms}
        onChange={(e) => {
          updateFlow(
            produce(pricingFlow, (draft) => {
              draft.additionalData.paymentTerms = e.target.value as any;
            }),
            false,
          );
        }}
        disabled={!editMode}
      >
        <option value="net_30">Net 30</option>
        <option value="net_60">Net 60</option>
        <option value="net_90">Net 90</option>
        <option value="other">Other</option>
      </select>
      {pricingFlow.additionalData.paymentTerms === 'other' && (
        <input
          type="text"
          className="mt-4 text-md rounded-md outline-1 outline-gray-300 bg-transparent p-2 text-gray-900 shadow-sm outline-none border-none focus-within:outline focus-within:outline-2 focus-within:outline-fuchsia-900 focus:border-none focus:ring-0 focus:ring-transparent w-full placeholder:text-gray-400"
          placeholder="Custom payment terms..."
          value={pricingFlow.additionalData.paymentTerms_Other}
          onChange={(e) => {
            updateFlow(
              produce(pricingFlow, (draft) => {
                draft.additionalData.paymentTerms_Other = e.target.value as any;
              }),
              false,
            );
          }}
          disabled={!editMode}
        ></input>
      )}
    </div>
  );
}
function NonStandardTermsSection(props: { approvalsNeeded: ApprovalsDisplay }) {
  const { approvalsNeeded } = props;
  const { pricingFlow, updateFlow, editMode } =
    usePricingFlowContext<HamsterPricingFlow>();

  function hasNonStandardTerms() {
    return pricingFlow.additionalData.priceProtection;
  }

  // for now, non standard terms only includes price protection
  // we will show this section if the user clicks to show it, or if the user has a non-standard term enabled
  const [showNonStandardTerms, setShowNonStandardTerms] = useState(
    hasNonStandardTerms(),
  );

  return (
    <div className="">
      <div className="flex gap-1">
        <button
          className="mr-2 flex h-5 w-5 items-center justify-center rounded-full border border-gray-200 bg-white"
          onClick={() => {
            setShowNonStandardTerms(
              (prevShowTerms) => hasNonStandardTerms() || !prevShowTerms,
            );
          }}
          disabled={hasNonStandardTerms()}
          title={
            hasNonStandardTerms()
              ? 'Remove non-standard terms to hide'
              : 'Show non-standard terms'
          }
        >
          {showNonStandardTerms ? (
            <ChevronDownIcon className="h-3 w-3" aria-hidden="true" />
          ) : (
            <ChevronRightIcon className="h-3 w-3" aria-hidden="true" />
          )}
        </button>
        <span className="text-sm font-medium">Non-Standard Terms</span>
      </div>

      <Transition
        show={showNonStandardTerms}
        enter="transition ease-out duration-100"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition ease-in duration-75"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="pt-2">
          <PriceProtectionAtRenewalCheckbox approvalsNeeded={approvalsNeeded} />
        </div>
      </Transition>
    </div>
  );
}
export default function HamsterTermsSection(props: {
  approvalsNeeded: ApprovalsDisplay;
}) {
  const { pricingFlow, updateFlow, editMode } =
    usePricingFlowContext<HamsterPricingFlow>();
  const { showToast } = useToast();

  const maybeApprovalSubscriptionTerms =
    props.approvalsNeeded.fieldsNeeded.subscriptionTerms;
  return (
    <div className="flex flex-col mt-4 mb-8 px-4 w-full">
      {/* Main content area with responsive columns */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
        {/* Left column - form inputs */}
        <div className="flex flex-col gap-4">
          {/* Input fields in a single column on small screens, 2 columns on medium+ */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {/* Start Date */}
            <div className="flex flex-col">
              <label className="mb-2 text-sm font-medium text-gray-900 min-h-6 flex items-center">
                Start Date
              </label>
              <input
                type="date"
                className="text-md rounded-md outline-1 outline-gray-300 bg-transparent p-2 text-gray-900 shadow-sm outline-none border-none focus-within:outline focus-within:outline-2 focus-within:outline-fuchsia-900 focus:border-none focus:ring-0 focus:ring-transparent w-full"
                id="startDate"
                value={pricingFlow.additionalData.startDate ?? ''}
                onChange={(e) => {
                  if (e.target.value) {
                    updateFlow(
                      produce(pricingFlow, (draft) => {
                        draft.additionalData.startDate = new Date(
                          e.target.value,
                        )
                          .toISOString()
                          .split('T')[0];
                      }),
                      false,
                    );
                  }
                }}
                disabled={!editMode}
              />
            </div>

            {/* Subscription Terms */}
            <div className="flex flex-col">
              <FormattedNumberField
                numberDecimals={0}
                className="text-md w-full rounded-md outline-1 outline-gray-300 bg-transparent p-2 text-gray-900 shadow-sm outline-none border-none focus-within:outline focus-within:outline-2 focus-within:outline-fuchsia-900 focus:border-none focus:ring-0 focus:ring-transparent"
                value={pricingFlow.additionalData.subscriptionTerms}
                updateValue={(value: number) => {
                  updateFlow(
                    produce(pricingFlow, (draft) => {
                      draft.additionalData.subscriptionTerms = value;
                    }),
                    false,
                  );
                }}
                suffix=" months"
                label={
                  <label className="text-sm font-medium text-gray-900 mb-2 min-h-6 flex items-center">
                    Subscription Terms
                    {maybeApprovalSubscriptionTerms && (
                      <span className="ml-2">
                        <ApprovalBadge
                          level={
                            maybeApprovalSubscriptionTerms?.greatestStep.name
                          }
                        />
                      </span>
                    )}
                  </label>
                }
                disabled={!editMode}
              />
            </div>

            {/* Billing Frequency */}
            <div className="flex flex-col">
              <BillingFrequencySelector
                approvalsNeeded={props.approvalsNeeded}
              />
            </div>

            {/* Payment Terms */}
            <div className="flex flex-col">
              <PaymentTermsSelector approvalsNeeded={props.approvalsNeeded} />
            </div>
          </div>

          {/* Non-Standard Terms Section - Same column as form inputs */}
          <div className="mt-4">
            <NonStandardTermsSection approvalsNeeded={props.approvalsNeeded} />
          </div>
        </div>

        {/* Right column - Non-Commercial Terms */}
        <div className="flex flex-col">
          <label className="text-sm font-medium text-gray-900 min-h-6 flex items-center mb-2">
            Non-Commercial Terms
          </label>
          <textarea
            className="text-md rounded-md outline-1 outline-gray-300 bg-transparent p-2 text-gray-900 shadow-sm outline-none border-none focus-within:outline focus-within:outline-2 focus-within:outline-fuchsia-900 focus:border-opacity-0 focus:ring-0 focus:ring-transparent w-full placeholder:text-gray-400"
            placeholder="Enter any other terms..."
            value={pricingFlow.additionalData.nonCommercialTerms}
            onBlur={() => {
              updateFlow(
                produce(pricingFlow, (draft) => {
                  draft.additionalData.nonCommercialTerms =
                    draft.additionalData.nonCommercialTerms.trim();
                }),
                false,
              );
            }}
            onChange={(e) => {
              updateFlow(
                produce(pricingFlow, (draft) => {
                  draft.additionalData.nonCommercialTerms = e.target.value;
                }),
                false,
              );
            }}
            disabled={!editMode}
          />
        </div>
      </div>
    </div>
  );
}
