import { isNil } from 'lodash';
import { createContext, useContext, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { AnalyticsEventPayload } from 'src/analytics_event';
import { User } from 'src/types';

const AnalyticsContext = createContext<
  ((event: AnalyticsEventPayload) => void) | null
>(null);

export function AnalyticsContextProvider({
  sessionId,
  user,
  children,
}: {
  sessionId: string;
  user: User | null;
  children: React.ReactNode;
}) {
  const [searchParams] = useSearchParams();
  const initialSource = useRef(searchParams.get('source'));

  // The first time we load the app, we record where the user came from for
  // analytics
  const externalReferrer = (() => {
    const EXTERNAL_REFERRER_LOCAL_STORAGE_KEY = 'externalReferrer';
    if (isNil(process.env.REACT_APP_CLIENT_BASE_URL)) {
      return null;
    }
    if (
      // if we were "referred" by ourselves, this is probably from people going
      // through the login flow, and we don't want to count that as the external
      // referrer
      document.referrer.includes(process.env.REACT_APP_CLIENT_BASE_URL) ||
      // Also explicitly exclude cases where people are being referred by google
      // accounts
      document.referrer.includes('accounts.google.com')
    ) {
      return localStorage.getItem(EXTERNAL_REFERRER_LOCAL_STORAGE_KEY);
    }
    localStorage.setItem(
      EXTERNAL_REFERRER_LOCAL_STORAGE_KEY,
      document.referrer,
    );
    return document.referrer;
  })();
  async function createAnalyticsEvent(event: AnalyticsEventPayload) {
    const fullEventData = {
      external_referrer: externalReferrer,
      source: initialSource.current,
      session_id: sessionId,
      user_id: user?.id,
      ...event.eventData,
    };
    console.log(`AnalyticsEvent: `, fullEventData);
    navigator.sendBeacon(
      `${process.env.REACT_APP_SERVER_BASE_URL}/api/v1/analytics_event`,
      new Blob(
        [
          JSON.stringify({
            name: event.name,
            eventData: fullEventData,
          }),
        ],
        { type: 'application/json' },
      ),
    );
  }
  return (
    <AnalyticsContext.Provider value={createAnalyticsEvent}>
      {children}
    </AnalyticsContext.Provider>
  );
}

export function useAnalyticsContext() {
  const context = useContext(AnalyticsContext);
  if (!context) {
    throw new Error(
      'useAnalyticsContext must be used within a AnalyticsContextProvider',
    );
  }
  return context;
}
