import _, { isNil } from 'lodash';
import { unreachable } from 'src/typeUtils';
import {
  Count,
  CurrencyValueFlat,
  CurrencyValueType,
  Tier,
  ZERO_FLAT,
} from '../types_common/price';
import {
  HamsterPricingInformation,
  HamsterProduct,
  HamsterProductPrice,
  HamsterQuotePrice,
} from './hamster_types';

export function tierForValue<T extends Tier>(
  tiers: T[] | null | undefined,
  quantity: number,
): T | null {
  if (isNil(tiers)) {
    return null;
  }
  return tiers
    .filter((t) => t.minimum.value <= quantity)
    .sort((a, b) => b.minimum.value - a.minimum.value)[0];
}

// looks up the list price for the tier in the pricing curve
export function getListPriceForVolume(
  volume: number,
  pricingInfo: HamsterPricingInformation,
) {
  const listPrice = pricingInfo.listPrice;
  switch (listPrice?.type) {
    case 'tiered':
      return tierForValue(listPrice?.tiers, volume)?.currencyValue;
    case CurrencyValueType.FLAT:
      return listPrice;
    case undefined:
      return null;
    default:
      unreachable(listPrice);
  }
}

// keep in sync with function of the same name on the server
// Used as the top level list price for products. e.g., for an enterprise
// customer with 400 seats, the blended list price would be ($275 * 99 seats +
// $250 * 301)/400 seats = $256.19 Per Seat Per Month. In this case, $275 and
// $250 are the straight list prices, as returned by getListPriceForVolume.
export function getBlendedPriceForVolume(
  volume: number,
  tiers: Tier<CurrencyValueFlat, Count>[] | null | undefined,
): CurrencyValueFlat | null {
  if (isNil(tiers)) {
    return null;
  }
  if (volume === 0) {
    return tiers[0].currencyValue ?? null;
  }
  const blendedValue =
    tiers.reduce((acc, tier, tierIdx): number => {
      const nextTier = tiers[tierIdx + 1];
      const tierPrice = tier.currencyValue;
      const tierStart = Math.max(tier.minimum.value, 1);
      if (tierStart > volume) {
        return acc;
      }
      const tierEnd =
        !nextTier || nextTier.minimum.value > volume
          ? volume
          : nextTier.minimum.value - 1;
      const tierVolume = Math.max(tierEnd - tierStart + 1, 0);
      return acc + tierVolume * tierPrice.value;
    }, 0) / volume;
  return {
    type: CurrencyValueType.FLAT,
    currency: tiers[0]?.currencyValue.currency ?? 'USD',
    value: blendedValue,
  };
}
export function maxVolumeForProduct(product: HamsterProduct) {
  return (
    product.volume +
    (_.max(product.rampedVolumeIncremental.map((v) => v?.value ?? 0)) ?? 0)
  );
}

export function estimatedMonthlyRevenue(
  product: HamsterProduct,
  productInfo: HamsterProductPrice,
  subscriptionTerms: number,
  // if number, it's the revenue from that product at that particular month.
  // Note that this is 0 indexed, so e.g. yearly billing happens at months 0,
  // 12, etc
  // If 'at_scale', it's the monthly revenue at scale
  // If 'prorated', if the cost is uneven from month to month, it's the average
  // cost per month
  monthIdx: number | 'for_arr_calc' | 'prorated',
): number {
  const sign = productInfo.isRebate === true ? -1 : 1;
  const revenueFrequency = productInfo.revenueFrequency;
  function volumeTimesPrice(volume: number, quotePrice: HamsterQuotePrice) {
    switch (quotePrice.type) {
      case CurrencyValueType.FLAT:
        return quotePrice.value * volume;
      case 'tiered':
        // waterfall - for each tier, measure the volume and price, and sum those
        // all together
        return quotePrice.tiers.reduce((acc, tier, tierIdx): number => {
          const nextTier = quotePrice.tiers[tierIdx + 1];
          const tierPrice = tier.currencyValue;
          const tierStart = Math.max(tier.minimum.value, 1);
          if (tierStart > volume) {
            return acc;
          }
          const tierEnd =
            !nextTier || nextTier.minimum.value > volume
              ? volume
              : nextTier.minimum.value - 1;
          const tierVolume = Math.max(tierEnd - tierStart + 1, 0);
          return acc + tierVolume * tierPrice.value;
        }, 0);
      default:
        unreachable(quotePrice);
    }
  }
  switch (monthIdx) {
    case 'for_arr_calc': {
      const volume = maxVolumeForProduct(product);
      const price =
        product.quotePrice ??
        productInfo.currentPricingCurve.pricingInformation.listPrice ??
        ZERO_FLAT('USD');
      switch (revenueFrequency) {
        case 'annual':
          return (sign * volumeTimesPrice(volume, price)) / 12;
        case 'one_time':
          return (
            (sign * volumeTimesPrice(volume, price)) /
            Math.max(subscriptionTerms, 12)
          );
        case 'monthly':
          return sign * volumeTimesPrice(volume, price);
        default:
          unreachable(revenueFrequency);
      }
    }
    case 'prorated': {
      const months = Array.from({ length: subscriptionTerms }, (_, i) => i);
      return (
        _.sum(
          months.map((monthIdx) =>
            estimatedMonthlyRevenue(
              product,
              productInfo,
              subscriptionTerms,
              monthIdx,
            ),
          ),
        ) / subscriptionTerms
      );
    }
    default: {
      const rampIncrementalVolume =
        product.rampedVolumeIncremental.length > monthIdx
          ? (product.rampedVolumeIncremental[monthIdx]?.value ?? 0)
          : 0;
      const rampTotalVolume = product.volume + rampIncrementalVolume;
      const price =
        product.quotePrice ??
        productInfo.currentPricingCurve.pricingInformation.listPrice ??
        ZERO_FLAT('USD');
      switch (revenueFrequency) {
        case 'annual':
          if (monthIdx % 12 === 0) {
            return sign * volumeTimesPrice(rampTotalVolume, price);
          } else {
            return 0;
          }
        case 'one_time':
          if (monthIdx === 0) {
            return sign * volumeTimesPrice(rampTotalVolume, price);
          } else {
            return 0;
          }
        case 'monthly':
          return sign * volumeTimesPrice(rampTotalVolume, price);
        default:
          unreachable(revenueFrequency);
      }
    }
  }
}
