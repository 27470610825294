import { ArrowDownRightIcon } from '@heroicons/react/20/solid';
import { useRef, useState } from 'react';
import Button from 'src/components/Button';
import { FormattedNumberField } from 'src/components/Fields';
import { VolumeChart } from 'src/components/graphs/VolumeChart';
import Toggle from 'src/components/Toggle';
import { classNames } from 'src/dashboard/App';
import { getHandleKeyDownForEnterNextRowHandling } from 'src/utils';
import { NonFullWidthSidebar } from '../Alpaca/Components/AlpacaQuotePriceEditableTieredDetails';
import { formatNumberAsUSDCV } from '../Hamster/HamsterQuoteTable';
import EditableIndicator from '../Penguin/Components/EditableIndicator';
import { Count } from '../types_common/price';

interface VolumeEditableProps {
  volume: number;
  updateValue: (value: number) => void;
  disabled?: boolean;
  productName: string;
  rampedVolumeIncremental: (Count | null)[];
  setRampedVolumeIncremental: (
    rampedVolumeIncremental: (Count | null)[],
  ) => void;
  months: number;
  calculateEstimatedMonthlyRevenue: (params: {
    baseVolume: number;
    rampedVolumeIncremental: (Count | null)[];
    monthIdx: number;
  }) => number;
}

export function VolumeEditable(props: VolumeEditableProps) {
  const {
    volume,
    updateValue,
    disabled,
    productName,
    rampedVolumeIncremental,
    setRampedVolumeIncremental,
    months,
    calculateEstimatedMonthlyRevenue,
  } = props;
  const inputRef = useRef<HTMLInputElement>(null);

  const [showVolumeDetails, setShowVolumeDetails] = useState(false);
  function hasRampUp() {
    return rampedVolumeIncremental.length > 0;
  }
  return (
    <>
      <EditableIndicator
        onClickSettings={
          disabled ? undefined : () => setShowVolumeDetails(true)
        }
      >
        <div
          className="flex flex-col justify-start items-start"
          onClick={
            hasRampUp() && disabled
              ? () => {
                  setShowVolumeDetails(true);
                }
              : undefined
          }
        >
          {hasRampUp() && (
            <div
              className="w-full text-gray-400 mb-[-8px] mt-[-4px] pl-3"
              style={{ fontSize: '10px', lineHeight: '12px' }}
            >
              Ramped
            </div>
          )}
          {!disabled ? (
            <FormattedNumberField
              type="text"
              value={volume}
              required={true}
              numberDecimals={0}
              className="cursor-pointer border-none bg-transparent text-xs sm:text-sm text-gray-900 outline-none focus:border-none focus:ring-0 focus:ring-transparent h-full w-28"
              data-volume-editable
              ref={inputRef}
              onKeyDown={getHandleKeyDownForEnterNextRowHandling(
                inputRef,
                'data-volume-editable',
              )}
              updateValue={(value: number) => updateValue(value)}
              disabled={disabled}
            />
          ) : (
            <div className="border-none bg-transparent text-xs sm:text-sm text-gray-900 outline-none focus:border-none h-full w-28 px-3 py-2">
              {volume.toLocaleString()}
            </div>
          )}
        </div>
      </EditableIndicator>
      <VolumeDetails
        show={showVolumeDetails}
        productName={productName}
        onClose={() => setShowVolumeDetails(false)}
        rampedVolumeIncremental={rampedVolumeIncremental}
        setRampedVolumeIncremental={setRampedVolumeIncremental}
        months={months}
        baseVolume={volume}
        disabled={disabled}
        calculateEstimatedMonthlyRevenue={calculateEstimatedMonthlyRevenue}
      />
    </>
  );
}

function VolumeDetails(props: {
  show: boolean;
  productName: string;
  onClose: () => void;
  rampedVolumeIncremental: (Count | null)[];
  setRampedVolumeIncremental: (
    rampedVolumeIncremental: (Count | null)[],
  ) => void;
  months: number;
  baseVolume: number;
  disabled?: boolean;
  calculateEstimatedMonthlyRevenue: (params: {
    baseVolume: number;
    rampedVolumeIncremental: (Count | null)[];
    monthIdx: number;
  }) => number;
}) {
  const {
    show,
    productName,
    onClose,
    months,
    baseVolume,
    disabled,
    calculateEstimatedMonthlyRevenue,
  } = props;
  const [rampedVolumeIncremental, setRampedVolumeIncremental] = useState(
    props.rampedVolumeIncremental,
  );
  function hasRampUp() {
    return rampedVolumeIncremental.length > 0;
  }
  function toggleRampUp() {
    if (!hasRampUp()) {
      setRampedVolumeIncremental(Array.from({ length: months }, () => null));
    } else {
      setRampedVolumeIncremental([]);
    }
  }
  function save() {
    props.setRampedVolumeIncremental(rampedVolumeIncremental);
    onClose();
  }

  return (
    <NonFullWidthSidebar isOpen={show} onClose={onClose} title={productName}>
      <div className="flex flex-col h-full">
        {/* Main content area with auto height and scrolling */}
        <div className="flex-1 overflow-y-auto">
          <div className="py-4 md:p-4">
            <div className="flex flex-row justify-between items-center p-2 md:px-0">
              <span className="text-xs sm:text-sm">Monthly ramp up</span>
              <Toggle
                enabled={hasRampUp()}
                onToggle={toggleRampUp}
                disabled={disabled}
              />
            </div>
            <div
              className={classNames('h-48 w-full', !hasRampUp() && 'invisible')}
            >
              <VolumeChart
                volumes={rampedVolumeIncremental.map((r) => r?.value ?? 0)}
              />
            </div>
            {
              <div
                className={classNames(
                  'rounded-none md:rounded-xl border border-gray-200 bg-white overflow-hidden',
                  !hasRampUp() && 'invisible',
                )}
              >
                <div className="overflow-auto">
                  <table className="border-separate border-spacing-0">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          className="sticky top-0 z-10 align-bottom w-full border-b bg-gray-50 p-2 md:px-6 md:py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter first:rounded-tl-xl"
                        >
                          Products
                        </th>
                        <th
                          scope="col"
                          className="sticky top-0 z-10 align-bottom border-b bg-gray-50 p-2 md:pl-4 md:pr-6 md:py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter whitespace-nowrap"
                        >
                          Step up
                        </th>
                        <th
                          scope="col"
                          className="sticky top-0 z-10 align-bottom border-b bg-gray-50 p-2 md:pl-4 md:pr-6 md:py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter whitespace-nowrap"
                        >
                          Quantity
                        </th>
                        <th
                          scope="col"
                          className="sticky top-0 z-10 align-bottom border-b bg-gray-50 p-2 md:px-6 md:py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter whitespace-nowrap last:rounded-tr-xl"
                        >
                          Est. Monthly Revenue
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {rampedVolumeIncremental.map((stepUp, i) => {
                        return (
                          <ProductRampUpRow
                            monthIdx={i}
                            monthNumber={i + 1}
                            key={i}
                            baseVolume={baseVolume}
                            stepUp={stepUp}
                            onChange={(value: Count) => {
                              const updated = [...rampedVolumeIncremental];
                              updated[i] = value;
                              setRampedVolumeIncremental(updated);
                            }}
                            disabled={disabled}
                            calculateEstimatedMonthlyRevenue={() =>
                              calculateEstimatedMonthlyRevenue({
                                baseVolume,
                                rampedVolumeIncremental,
                                monthIdx: i,
                              })
                            }
                          />
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            }
          </div>
        </div>

        {/* Footer */}
        <div className="sticky bottom-0 bg-white border-t border-100">
          <div className="flex flex-row justify-between p-4 gap-4">
            <Button
              onClick={() => {
                // reset the ramp up
                setRampedVolumeIncremental(props.rampedVolumeIncremental);
                onClose();
              }}
              label={!disabled ? 'Cancel' : 'Back'}
              color="white"
              className="flex-1"
            />
            {!disabled && (
              <Button onClick={save} label="Save" className="flex-1" />
            )}
          </div>
        </div>
      </div>
    </NonFullWidthSidebar>
  );
}

function ProductRampUpRow(props: {
  monthIdx: number;
  monthNumber: number;
  baseVolume: number;
  stepUp: Count | null;
  onChange: (value: Count) => void;
  disabled?: boolean;
  calculateEstimatedMonthlyRevenue: () => number;
}) {
  const {
    monthNumber,
    monthIdx,
    baseVolume,
    stepUp,
    onChange,
    disabled,
    calculateEstimatedMonthlyRevenue,
  } = props;
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <tr className="">
      <td className="p-2 sm:px-6 sm:py-4 whitespace-nowrap text-xs sm:text-sm text-gray-900">
        <span className="flex flex-row gap-1 sm:gap-2 items-center">
          <ArrowDownRightIcon
            className="h-4 w-4 text-gray-400"
            aria-hidden="true"
          />
          Month {monthNumber}
        </span>
      </td>
      {/* Quantity */}
      <td className="whitespace-nowrap text-xs sm:text-sm text-gray-900">
        {monthIdx === 0 ? (
          <div className="pl-4 text-xs sm:text-sm">0</div> // You can't edit month 1, that's just the base volume
        ) : (
          <EditableIndicator className="flex flex-col justify-start items-start">
            <FormattedNumberField
              type="text"
              value={stepUp?.value ?? 0}
              required={true}
              numberDecimals={0}
              className="cursor-pointer border-none bg-transparent text-xs sm:text-sm text-gray-900 outline-none focus:border-none focus:ring-0 focus:ring-transparent h-full w-28"
              data-volume-editable
              ref={inputRef}
              onKeyDown={getHandleKeyDownForEnterNextRowHandling(
                inputRef,
                'data-volume-editable',
              )}
              updateValue={(value: number) => {
                onChange({
                  value,
                  type: 'count',
                });
              }}
              disabled={disabled}
            />
          </EditableIndicator>
        )}
      </td>
      <td className="whitespace-nowrap text-xs sm:text-sm text-gray-900 p-2 sm:px-6 sm:py-4">
        {baseVolume + (stepUp?.value ?? 0)}
      </td>
      {/* Est. Monthly revenue */}
      <td className="whitespace-nowrap p-2 sm:px-6 sm:py-4 text-xs sm:text-sm font-medium text-gray-500">
        {formatNumberAsUSDCV(calculateEstimatedMonthlyRevenue(), 2)}
      </td>
    </tr>
  );
}
