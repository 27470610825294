import { datadogRum } from '@datadog/browser-rum';
import { isNil } from 'lodash';
import api from 'src/api';
import Button, { ButtonProps } from 'src/components/Button';
import { useModal } from 'src/components/Modal';
import { useToast } from 'src/components/Toast';
import { User } from 'src/types';
import { OpportunityDetailPageConfig } from '../Opportunity/OpportunityDetailPage';
import { PricingFlowGroup } from '../Opportunity/types';
import ApprovalOptionsModal, {
  ApprovalModalMode,
} from './Approvals/ApprovalOptionsModal';
import { PricingFlowOrSnapshotForNavigation } from './QuoteOptionsSection';
import { PricingFlow, PricingFlowType } from './types';

export function isPricingFlowValidForSubmission(pricingFlow: PricingFlow): {
  isValid: boolean;
  error: string;
} {
  switch (pricingFlow.type) {
    case PricingFlowType.HAMSTER:
    case PricingFlowType.HAMSTER_FOR_DEMO:
      if (isNil(pricingFlow.additionalData.startDate)) {
        return { isValid: false, error: 'Please set a start date' };
      }
      return { isValid: true, error: '' };
    case PricingFlowType.DEALOPS:
    case PricingFlowType.PENGUIN:
    case PricingFlowType.ALPACA:
    default:
      datadogRum.addError('Validation not implemented yet!');
      return { isValid: true, error: '' };
  }
}

export default function SubmitForApprovalButton(props: {
  buttonProps: Omit<ButtonProps, 'onClick'>;
  pricingFlow: PricingFlowOrSnapshotForNavigation;
  user: User;
  approvalModalMode: ApprovalModalMode;
  pageConfig: OpportunityDetailPageConfig;
}) {
  const { buttonProps, pricingFlow, user, approvalModalMode, pageConfig } =
    props;
  const { showToast } = useToast();
  const { showModal } = useModal();
  if (!user.permissions.includes('edit_pricing_flow')) {
    return null;
  }
  return (
    <Button
      {...buttonProps}
      onClick={async (e) => {
        e.stopPropagation();
        const pricingFlowWithOpportunity = (
          await api.get(
            `pricingFlow?pricingFlowId=${pricingFlow.originalPricingFlowId}`,
          )
        ).data.pricingFlowData;
        const { isValid, error } = isPricingFlowValidForSubmission(
          pricingFlowWithOpportunity,
        );
        if (!isValid) {
          showToast({
            title: 'Error',
            subtitle: error,
            type: 'error',
          });
          return;
        }

        const activeGroups = (
          await api.get('pricingFlowGroups', {
            opportunityId: pricingFlowWithOpportunity.opportunity.id,
          })
        ).data.filter((g: PricingFlowGroup) => isNil(g.archivedAt));
        const activeGroupsWithThisQuote = activeGroups.filter(
          (group: PricingFlowGroup) => {
            return group.pricingFlowOnGroup.some(
              (p) => p.pricingFlowId === props.pricingFlow.id,
            );
          },
        );

        const { title, subtitle } = (() => {
          if (activeGroups.length === 0) {
            return {
              title: 'Select quotes to include in approval request',
              subtitle:
                'Save time by requesting approval for multiple quotes at once',
            };
          } else {
            return {
              title: 'Choose approval method',
              subtitle:
                activeGroupsWithThisQuote.length > 0
                  ? 'Submit this quote alone or with its group'
                  : 'Submit this quote alone or add it to an existing group',
            };
          }
        })();
        showModal({
          newStyle: true,
          // title: approvalModalTitle,
          title,
          subtitle,
          children: (
            <ApprovalOptionsModal
              pricingFlow={pricingFlowWithOpportunity}
              opportunity={pricingFlowWithOpportunity.opportunity}
              user={user}
              mode={approvalModalMode}
              pageConfig={pageConfig}
              activeGroups={activeGroups}
            />
          ),
        });
      }}
    />
  );
}
