import _, { isNil } from 'lodash';
import { classNames } from 'src/dashboard/App';
import { usePricingFlowContext } from '../PricingFlow';
import { formatNumberAsUSDCV } from './HamsterQuoteTable';
import { HamsterPricingFlow, HamsterProductPrice } from './hamster_types';
import { estimatedMonthlyRevenue } from './hamster_utils';

type ProductType = 'credit' | 'revenue' | 'all';
function productMatchesFilter(
  productInfo: HamsterProductPrice,
  filter: ProductType,
) {
  if (filter === 'credit' && productInfo.isRebate !== true) {
    return false;
  }
  if (filter === 'revenue' && productInfo.isRebate === true) {
    return false;
  }
  return true;
}
function computeTCV(pricingFlow: HamsterPricingFlow, filterTo: ProductType) {
  return pricingFlow.products.reduce((acc, product) => {
    const productInfo = pricingFlow.pricingSheetData.productInfo[product.id];
    if (isNil(productInfo)) {
      return acc;
    }
    if (!productMatchesFilter(productInfo, filterTo)) {
      return acc;
    }
    const months = Array.from(
      { length: pricingFlow.additionalData.subscriptionTerms },
      (_, i) => i,
    );
    return (
      acc +
      _.sum(
        months.map((monthIdx) => {
          return estimatedMonthlyRevenue(
            product,
            productInfo,
            pricingFlow.additionalData.subscriptionTerms,
            monthIdx,
          );
        }),
      )
    );
  }, 0);
}

function computeArrAtScale(
  pricingFlow: HamsterPricingFlow,
  filterTo: ProductType,
) {
  return (
    pricingFlow.products.reduce((acc, product) => {
      const productInfo = pricingFlow.pricingSheetData.productInfo[product.id];
      if (isNil(productInfo)) {
        return acc;
      }
      if (!productMatchesFilter(productInfo, filterTo)) {
        return acc;
      }
      return (
        acc +
        estimatedMonthlyRevenue(
          product,
          productInfo,
          pricingFlow.additionalData.subscriptionTerms,
          'for_arr_calc',
        )
      );
    }, 0) * 12
  );
}
export default function HamsterAnnualRevenueTable(props: {
  className?: string;
}) {
  const { pricingFlow } = usePricingFlowContext<HamsterPricingFlow>();

  const tcvCredits = computeTCV(pricingFlow, 'credit');
  const tcvRevenue = computeTCV(pricingFlow, 'revenue');

  const arrCredits = computeArrAtScale(pricingFlow, 'credit');
  const arrRevenue = computeArrAtScale(pricingFlow, 'revenue');

  const revenueCellStyle =
    'font-normal text-md md:text-lg text-gray-700 p-2 md:px-6 md:py-3.5 border-gray-200';
  const oppData = pricingFlow.opportunity.opportunityData;
  const baselineAcv =
    'Baseline_ACV__c' in oppData ? oppData.Baseline_ACV__c : 0;
  return (
    <div
      className={classNames(
        'w-full md:w-auto overflow-x-auto rounded-none md:rounded-xl border border-gray-200 bg-white',
        props.className,
      )}
    >
      <table className="w-full md:w-auto border-separate border-spacing-0">
        <thead>
          <tr className="bg-gray-50">
            <th className="rounded-none md:rounded-tl-xl border-b border-gray-200"></th>
            <th className="font-medium text-xs text-left align-bottom whitespace-nowrap p-2 md:px-6 md:py-3.5 text-gray-700 border-b border-gray-200">
              Gross Revenue
            </th>
            <th className="font-medium text-xs text-left align-bottom whitespace-nowrap p-2 md:px-6 md:py-3.5 text-gray-700 border-b border-gray-200">
              Credits
            </th>
            <th className="font-medium text-xs text-left align-bottom whitespace-nowrap rounded-none md:rounded-tr-xl p-2 md:px-6 md:py-3.5 text-gray-700 border-b border-gray-200">
              Net Total
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="font-medium text-xs text-gray-700 p-2 md:px-6 md:py-3.5 border-b border-gray-200">
              TCV
            </td>
            {/* TCV - revenue */}
            <td className={classNames(revenueCellStyle, 'border-b')}>
              {formatNumberAsUSDCV(tcvRevenue, 0)}
            </td>
            {/* TCV - credits */}
            <td
              className={classNames(revenueCellStyle, 'border-b text-red-900')}
            >
              {formatNumberAsUSDCV(tcvCredits, 0)}
            </td>
            {/* TCV - net */}
            <td className={classNames(revenueCellStyle, 'border-b')}>
              {formatNumberAsUSDCV(tcvRevenue + tcvCredits, 0)}
            </td>
          </tr>
          <tr>
            <td className="font-medium text-xs text-gray-700 p-2 md:px-6 md:py-3.5 border-b border-gray-200">
              ARR
            </td>
            {/* ARR - revenue */}
            <td className={classNames(revenueCellStyle, 'border-b')}>
              {formatNumberAsUSDCV(arrRevenue, 0)}
            </td>
            {/* ARR - credits */}
            <td
              className={classNames(
                classNames(revenueCellStyle, 'border-b'),
                'text-red-900',
              )}
            >
              {formatNumberAsUSDCV(arrCredits, 0)}
            </td>
            {/* ARR - net */}
            <td className={classNames(revenueCellStyle, 'border-b')}>
              {formatNumberAsUSDCV(arrRevenue + arrCredits, 0)}
            </td>
          </tr>
          <tr>
            <td className="font-medium text-xs text-gray-700 p-2 md:px-6 md:py-3.5 whitespace-nowrap">
              Net New ARR
            </td>
            {/* net new ARR - revenue */}
            <td className={classNames(revenueCellStyle)}></td>
            {/* net new ARR - credits */}
            <td className={classNames(revenueCellStyle)}></td>
            {/* net new ARR - net */}
            <td className={classNames(revenueCellStyle, 'font-semibold')}>
              {formatNumberAsUSDCV(
                arrRevenue + arrCredits - (baselineAcv ?? 0),
                0,
              )}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
