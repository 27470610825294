import { PencilIcon } from '@heroicons/react/24/outline';
import { useEffect, useRef, useState } from 'react';

export default function EditableTitle(props: {
  title: string;
  updateTitle: (title: string) => void;
  disabled: boolean;
  textSize?: 'text-sm' | 'text-base' | 'text-lg' | 'text-xs md:text-sm';
  fontWeight?: 'font-normal' | 'font-medium' | 'font-semibold';
  className?: string;
}) {
  const {
    updateTitle,
    disabled,
    textSize = 'text-lg',
    fontWeight = 'font-semibold',
    className = '',
  } = props;
  const [title, setTitle] = useState<string>(props.title);
  const [isEditing, setIsEditing] = useState(false);
  const [inputWidth, setInputWidth] = useState(0);

  const spanRef = useRef<HTMLSpanElement>(null);
  useEffect(() => {
    if (spanRef.current) {
      setInputWidth(spanRef.current.offsetWidth + 19);
    }
  }, [title]);

  const sharedStyles = `${textSize} ${fontWeight}`;

  // Height utilities based on text size
  const heightStyles = {
    'text-sm': 'h-8',
    'text-base': 'h-9',
    'text-lg': 'h-10',
    'text-xs md:text-sm': 'h-6 md:h-8',
  }[textSize];

  const classNames = (...classes: string[]) => {
    return classes.filter(Boolean).join(' ');
  };

  return (
    <div className={classNames('inline-block w-full', className)}>
      {isEditing ? (
        <input
          type="text"
          value={title}
          onChange={(e) => {
            setTitle(e.target.value);
          }}
          onBlur={() => {
            updateTitle(title);
            setIsEditing(false);
          }}
          disabled={disabled ?? !isEditing}
          autoFocus
          style={{ width: `${inputWidth}px` }}
          className={classNames(
            sharedStyles,
            heightStyles,
            'border border-gray-300 focus:outline-none focus:ring-1 focus:ring-fuchsia-900 rounded-md px-2 w-full text-ellipsis overflow-hidden leading-normal',
            className,
          )}
          title="Rename"
        />
      ) : (
        <h1
          onClick={() => {
            if (!disabled) {
              setIsEditing(true);
            }
          }}
          className={classNames(
            sharedStyles,
            heightStyles,
            'cursor-default border border-transparent rounded-md px-2 w-full truncate flex flex-row items-center leading-normal group',
            !disabled ? 'hover:border-gray-300 hover:cursor-text' : '',
            className,
          )}
          title={title}
        >
          <span className="truncate">{title}</span>
          {!disabled && !isEditing && (
            <PencilIcon className="w-3.5 h-3.5 md:w-4 md:h-4 text-gray-400 flex-shrink-0 ml-2" />
          )}
        </h1>
      )}
      <span
        ref={spanRef}
        className={classNames(
          sharedStyles,
          'absolute invisible whitespace-pre',
          className,
        )}
      >
        {title}
      </span>
    </div>
  );
}
